import { Lightning } from '@lightningjs/sdk';
import Movies from "./epg-data-sample.json"
import Featured from './Featured'
import categories from './channels-category.json'
import { List } from '@lightningjs/ui'
import { Grid } from '@lightningjs/ui'
import ChannelList from './ChannelList'
import ProgramList from './ProgramList';

async function logCategories() {
    return categories;
  }

  async function logMovies() {
    return Movies;  
  }
 
 //      shader: {type: Lightning.shaders.Perspective, rx: 3.14},
 
export default class Epglist extends Lightning.Component {
 
static _template() {
  if(localStorage.getItem('language')=="en"){
  return {
    Grids:{
    w:(w)=>w,
    // h:600,
    h:this.bindProp("Categorylenght3"),
 
       collision:true,
      //  clipping:true,  
    // autoResize:true,
      // flexItem: { marginBottom: 8 ,grow: 1},
    IconWrapper:{
        w:(w)=>w,  
        h:60,  
        rect:true,
        type:Featured,
        name:this.bindProp("Categoryname"),
        titleImg:this.bindProp("CategoryImg"),
        xValue:this.bindProp("iconshift"),
        color:0x00032226,
        direction: 'column',  
        collision:true
        },
 
        Wrapper3:{
                  y:76,
                  w:(w)=>w,
                  collision:true,
               clipping:true,
                  h:this.bindProp("Categorylenght2"),  
                  // h:(h)=>h,
 
                  rect:true,
                  color:0x00032226,
                  flex:{ direction: 'row'},
             ChannelWrapper: {
              w: 220,
              h: (h)=>h-60,
              zIndex: 2,
              rect: true,
              clipping: true,
              // autoResize: true,
              color:0x002E6D75, // 0xff495057, //0xff0D2B3D, //0xff035767, //0xff021212,
              // color:0xff032226,
              autoResize:true,
              Channels: {
                flexItem: { marginLeft: 8 },
                type: List,
                direction: 'column',
                spacing: 55,
                scroll:  { after:2 },    
                clipping: true,
                autoResize: true,
 
              },
             },
             ProgramWrapper: {
              x:15,
              // flexItem: { marginLeft: 8 },
              w: 1387,
              h: (h)=>h-60,
              zIndex: 2,
              rect: true,
              clipping: true,
              // autoResize: true,
              // color: 0xff2E6D75,//0xff495057,//  0xff0D2B3D, // Dark teal blue color,0xff021212,
              color:0x00032226,
              Programs: {
                clipping: true,
                spacing:55,
                direction: 'column',  
                type: List,
                autoResize: true,
                scroll: { after:2} ,  
   
                signals: { onIndexChanged: 'listIndexChanged' },
               
              },
             },
            },
        }
  }
}
else{
  return {
    Grids:{
    w:(w)=>w,
    // h:600,
    h:this.bindProp("Categorylenght3"),
 
       collision:true,
      //  clipping:true,  
    // autoResize:true,
      // flexItem: { marginBottom: 8 ,grow: 1},
    IconWrapper:{
        w:(w)=>w,  
        h:60,  
        rect:true,
        type:Featured,
        // name:this.bindProp("Categoryname"),
        name:this.bindProp("Categorynamear"),
        titleImg:this.bindProp("CategoryImg"),
        xValue:this.bindProp("iconshiftar"), 
        color:0x00032226,
        direction: 'column',  
        collision:true
        },
 
        Wrapper3:{
                  y:76,
                  w:(w)=>w,
                  collision:true,
               clipping:true,
                  h:this.bindProp("Categorylenght2"),  
                  // h:(h)=>h,
 
                  rect:true,
                  color:0x00032226,
                  flex:{ direction: 'row'},
             ChannelWrapper: {
              w: 220,
              h: (h)=>h-60,
              zIndex: 2,
              rect: true,
              clipping: true,
              // autoResize: true,
              color:0x002E6D75, // 0xff495057, //0xff0D2B3D, //0xff035767, //0xff021212,
              // color:0xff032226,
              autoResize:true,
              Channels: {
                flexItem: { marginLeft: 8 },
                type: List,
                direction: 'column',
                spacing: 55,
                scroll:  { after:2 },    
                clipping: true,
                autoResize: true,
 
              },
             },
             ProgramWrapper: {
              x:15,
              // flexItem: { marginLeft: 8 },
              w: 1387,
              h: (h)=>h-60,
              zIndex: 2,
              rect: true,
              clipping: true,
              // autoResize: true,
              // color: 0xff2E6D75,//0xff495057,//  0xff0D2B3D, // Dark teal blue color,0xff021212,
              color:0x00032226,
              Programs: {
                clipping: true,
                spacing:55,
                direction: 'column',  
                type: List,
                autoResize: true,
                scroll: { after:2} ,  
   
                signals: { onIndexChanged: 'listIndexChanged' },
               
              },
             },
            },
        }
  }
}
}

_init(){
  this.Categorylenght2=0  
  this.first=0
}
set namear(nameCatear){
  this.Categorynamear=nameCatear
 
 
  let b=this.Categorynamear.length
  this.iconshiftar=(b*7)+65      
}
set name(nameCate){
  this.Categoryname=nameCate
 
 
  let a=this.Categoryname.length
  this.iconshift=(a*11)+65      
}
set wrapperid(wid){
  this.wrapperidepg=wid;
}

set cd(movies3){
  this.movies=movies3
}
set len(l){
 
    this.Categorylenght=l
}
set allCate(ac){
  this.ac=ac 
}
 
set titleImg(i){
    this.CategoryImg=i
}
 
async _setup() {
    let ChannelItems =[]
    let ProgramItems = []
this.flag=0;
    // let channelsDatanew = await logMovies()
    let channelsDatanew =  this.movies//JSON.parse(localStorage.getItem('epg-channel-data'));
    let categoriesData =  await logCategories()
    let stopper=0
    let id=0
    let channelsData=channelsDatanew;
    channelsData.data.forEach(channel => {
        let categ2=channel.category;
        let capitalizedCate2 = categ2.charAt(0).toUpperCase() + categ2.slice(1);
                if(this.Categoryname==capitalizedCate2){
                  const dvrr = [channel.type , channel.events];
                  ProgramItems.push({ type: ProgramList, number: id, dvrr, stopper, imageChannel:channel.images[4]}, );
                  id++
                  if(stopper==0){
                    stopper=1;
                  }  
       
                  const imageUrls = [channel.images[4].url, channel.images[1].url];
                  if(channel.images[4].url){
                    if (imageUrls[0].startsWith('http://')) {
                      imageUrls[0]= 'https://' + imageUrls[0].slice(7);  
                    }
                  }
   
                  if(channel.images[1].url){
                    if (imageUrls[1].startsWith('http://')) {
                      imageUrls[1]= 'https://' + url.slice(7);
                    }  
                  }
                  if(imageUrls[0]=='' && imageUrls[1]!=''){
                    ChannelItems.push({ type: ChannelList, number: channel.id, name: imageUrls[1] });  
                  }else if(imageUrls[0]!=''){
                  ChannelItems.push({ type: ChannelList, number: channel.id, name: imageUrls[0]});
                  }
                  else{
                    ChannelItems.push({ type: ChannelList, number: channel.id, name: 'static/images/preview.png'});
                  }
                }
               
            // });
 
          })
         
         
         
          this.tag('Programs').add(ProgramItems)
          this.tag('Channels').add(ChannelItems) 
       this.Categorylenght2=id*200
       if(this.Categorylenght2>400){
       this.Categorylenght3=400
       this.Categorylenght2=360
       }
           
          this.previousIndex = 0
          this.currIdx = 0
          this.positionX = 0
         
}
_getFocused() {
    return this.tag('Programs');
}
 
static get width() {
    return 1463
  }
  static get height() {
    return 515
  }
 
 
  async listIndexChanged(obj) {
   
    // this.fireAncestors('$gridscrollvalue',this.wrapperscroll);
    this.currIdx = obj.index
    if (this.currIdx >= this.previousIndex) {
      this.tag('Channels').next()
      // this.tag('TimeWrapper').next()
      this.previousIndex = this.currIdx
    } else {
      this.tag('Channels').previous()
      // this.tag('TimeWrapper').previous()
      this.previousIndex = this.currIdx
     
    }
  }
 
 
  // async listIndexChanged(obj) {
  //   // let channelsDatanew = await logMovies()
  //   // this.movieObj=channelsDatanew.data[obj.index].events[this.currentIndexNum];
  //   // this.channelObj=channelsDatanew.data[obj.index].images[2];  
  //   //  this.movieImage=channelsDatanew.data[obj.index].events[this.currentIndexNum].images[0  ].url
  //   // const scrollValue = this.tag('Grids').tag('Wrapper').children[obj.index].y
  //   // this.fireAncestors('$moveList2', scrollValue)
  //   // const indexNum=0;
  //   // this.fireAncestors('$gridIndexChanged1', indexNum);
  //   this.currIdx = obj.index
  //   if (this.currIdx >= this.previousIndex) {
  //     this.tag('Channels').next()
  //     // this.tag('TimeWrapper').next()
  //     this.previousIndex = this.currIdx
  //   } else {
  //     this.tag('Channels').previous()
  //     // this.tag('TimeWrapper').previous()
  //     this.previousIndex = this.currIdx
     
  //   }
  // }
//   async $gridIndexChanged3(indexNum, MovieDetail) {
//     let channelsDatanew = await logMovies()
// // Update the class property with the new indexNum
//     if(this.wrapperidepg==0 &&  this.first==0){
//     this.currentIndexNum = indexNum;
//   this.what=MovieDetail
//   this.movieImage=channelsDatanew.data[this.flag].events[this.currentIndexNum].images[0].url
//   this.fireAncestors('$forDetailScreenObj2', this.what, this.imageChannel);
//     }
//     this.first++
//  }
 
 
 $moveList(scrollValue) {
 
    this.tag('Programs').items.map((d) => {
        if (d.isAlive) {
            d.tag('Grids')._scrollTransition.start(-scrollValue);
        }
    });
    this.positionX = scrollValue;
   
    if(this.wrapperidepg==0 && this.flag==0){
    this.fireAncestors('$scrollToTimeWrapper', scrollValue);
    this.fireAncestors('$gridscrollvalue',scrollValue, this.wrapperidepg);
    this.fireAncestors('$timegridscrollvalue',scrollValue);//new
    this.flag++
    }
    else if(this.wrapperidepg==0 && this.flag<this.Categorylenght && this.flag!=0){
      this.flag++
    }
    else if(this.wrapperidepg!=0 && this.flag==0){
      this.wrapperscroll=scrollValue
        this.fireAncestors('$gridscrollvalue',this.wrapperscroll, this.wrapperidepg);

      this.flag++
      }
    else if(this.wrapperidepg!=0 && this.flag<this.Categorylenght && this.flag!=0){
      // this.fireAncestors('$gridscrollvalue',this.wrapperscroll);
      // this.fireAncestors('$scrollToTimeWrapper', scrollValue);
      if(this.Categorylenght>3){
        this.flag= this.flag +(this.Categorylenght-this.flag)+1
      }
      else{
      this.flag++
      }
    }
    else if(this.wrapperidepg!=0 && this.wrapperidepg>2 && this.flag<this.Categorylenght && this.flag!=0){
      this.fireAncestors('$gridscrollvalue',this.wrapperscroll);
      this.fireAncestors('$scrollToTimeWrapper', scrollValue);
      this.flag++
    }
    else if(this.flag>=this.Categorylenght ){
      this.fireAncestors('$scrollToTimeWrapper', scrollValue);
      this.fireAncestors('$timegridscrollvalue',scrollValue);//new
      this.fireAncestors('$gridscrollvalue',scrollValue, this.wrapperidepg)

    }
 
  }
 
 
  // $moveList(scrollValue) {
  //   this.tag('Programs').items.map((d) => {
  //       if (d.isAlive) {
  //           d.tag('Grids')._scrollTransition.start(-scrollValue);
  //       }
  //   });
  //   this.positionX = scrollValue;
  //   this.fireAncestors('$scrollToTimeWrapper', scrollValue);
 
  // }
 
 
 
 
 
}
 


