import { Lightning } from '@lightningjs/sdk';
// ---------
export default class TimeList extends Lightning.Component {

static _template() {
  if(localStorage.getItem('language')=="en"){
  return {
    Wrapper: {
      y:3,
      w: (w) => w,
      collison:true,
      h: (h) => h,
      rect: true,
      color:0x00032226, 
      clipping:true,
      UsingTexture:{ 
        w: 40,
        h: 40,
        // mountX:-0.2,
        mountY:-0.3,

      texture: {
         type: lng.textures.ImageTexture, 
        src: this.bindProp("titleImg"),
      },
      }, 
      Text: {
        x:45,
        mountY:-0.5, 
        color: 0xffffffff,
        text: {
         text: this.bindProp("name"),   
          fontFace: 'Regular',
          fontStyle:'bold',
          fontSize: 24,
        },
        
  
      },
       StraightLine:{
        y:35, 
        x:this.bindProp("xValue"),
        w:1463,
        h:3, 
        rect:true,
        color:0xff2b444a,
      },
    },
  }
}
else{
  return {
    Wrapper: {
      y:3,
      w: (w) => w,
      collison:true,
      h: (h) => h,
      rect: true,
      color:0x00032226, 
      clipping:true,
      UsingTexture:{ 
        w: 40,
        h: 40,
        // mountX:-0.2,
        mountY:-0.3,
      texture: {
         type: lng.textures.ImageTexture, 
        src: this.bindProp("titleImg"),
      },
      shader: {type: Lightning.shaders.Perspective, rx: 3.14},
      }, 
      Text: {
        x:45,
        mountY:-0.5, 
        color: 0xffffffff,
        text: {
         text: this.bindProp("name"),   
        //  text:"Featured",b
          fontFace: 'Regular',
          fontStyle:'bold',
          fontSize: 24,
        },
        shader: {type: Lightning.shaders.Perspective, rx: 3.14},
      },
       StraightLine:{
        y:35, 
        x:this.bindProp("xValue"),
        w:1463,
        h:3, 
        rect:true,
        color:0xff2b444a,
      },
    },
  }
} 
}

static get width() {
  return 1463
}

static get height() {
  return 60
}
}
 