import { Lightning, Log } from '@lightningjs/sdk'
 
export default class LiveGrid extends Lightning.Component {
    // ------------
 
  static _template() {
    if(localStorage.getItem('language')=="en"){  
        return {
      Wrapper: {
        w: (w) => w,
        h: 60,
        rect: true,
        shader: {type:  Lightning.shaders.RoundedRectangle, radius: [0, 0, 8,8], stroke: 2 ,strokeColor: 0x33d1d3d4,},
        // color:0xff0D2B3D,// color: 0xff0D2B3D, // Dark teal blue color,0xffff2323,
        color: 0x1affffff, //0xaa035767,// 0x77777777,//
        Boxid: this.bindProp('boxid'),
         Text: {
          mountY: 0.5,
          y:(h)=>h/2+1,
          x:20,
          color: 0xffffffff,
          text: {
            text: this.bindProp('title'),
            fontFace: 'Regular',
            fontSize: 24,
            textAlign: 'left',
          },
          shader: {
            type:  Lightning.shaders.RoundedRectangle, radius: [0, 0,0,0], stroke: 0,strokeColor:  0x88ffffff,
          },
   
        },
      },
      HighLight: {
        y:10,
        w: (w) => w + 1,
        h: (h) => h + 1,
        alpha: 1,
        rect: true,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 5.4,
          stroke: 1,
          strokeColor: 0xffd1d3d4,
          fillColor: 0x00000000,
        },
        visible: false, 
      },
    }
  }
  else{
    return {
      Wrapper: {
        w: (w) => w,
        h: 60,
        rect: true,
        shader: {type:  Lightning.shaders.RoundedRectangle, radius: [0, 0, 8,8], stroke: 2 ,strokeColor: 0x33d1d3d4,},
        // color:0xff0D2B3D,// color: 0xff0D2B3D, // Dark teal blue color,0xffff2323,
        color: 0x1affffff, //0xaa035767,// 0x77777777,//
        Boxid: this.bindProp('boxid'),
         Text: {
          mountY: 0.5,
          y:(h)=>h/2+1,
          x:20,
          color: 0xffffffff,
          text: {
            text: this.bindProp('title'),
            fontFace: 'Regular',
            fontSize: 24,
            textAlign: 'left',
          },
          shader: {
            type:  Lightning.shaders.RoundedRectangle, radius: [0, 0,0,0], stroke: 0,strokeColor:  0x88ffffff,
          },          shader: {type: Lightning.shaders.Perspective, rx: 3.14}

   
        },
      },
      HighLight: {
        y:10,
        w: (w) => w + 1,
        h: (h) => h + 1,
        alpha: 1,
        rect: true,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 5.4,
          stroke: 1,
          strokeColor: 0xffd1d3d4,
          fillColor: 0x00000000,
        },
        visible: false, 
      },
    }
  }
  }
 
  // _handleLeft() {
  //       // this.index--
 
  //   }
 
  // _handleRight() {
  //       // this.index++
  //   }
 
 
  _focus() {
    this.tag('HighLight').patch({ visible: true })
   
  }
 
  _unfocus() {
    this.tag('HighLight').patch({ visible: false })
  }
 
  static get width() {
    return 500   
  }
   
  static get height() {
    return 60
  }
}   