import { Lightning } from '@lightningjs/sdk';
import Movies from "./epg-data-sample.json"
import Featured from './Featured'
import categories from './channels-category.json'
import { List } from '@lightningjs/ui'
import { Grid } from '@lightningjs/ui'
import ChannelList from './ChannelList' 
import ProgramList5 from './ProgramList5'; 

async function logCategories() { 
    return categories;
  }
  
  async function logMovies() {
    return Movies;  
  }
 
 
 
export default class Epglist1 extends Lightning.Component {
 
static _template() {
  if(localStorage.getItem('language')=="en"){
  return {
    Grids:{
    w:(w)=>w,
    // h:600,
    h:this.bindProp("Categorylenght3"),
 
       collision:true,
      //  clipping:true,  
    // autoResize:true,
      // flexItem: { marginBottom: 8 ,grow: 1},
    IconWrapper:{
        w:(w)=>w,  
        h:60,  
        rect:true,
        type:Featured,
        name:this.bindProp("Categoryname"),
        titleImg:this.bindProp("CategoryImg"),
        xValue:this.bindProp("iconshift"),
        color:0x00032226,
        direction: 'column',  
        collision:true
        },
 
        Wrapper3:{
                  y:76,
                  w:(w)=>w,
                  collision:true,
               clipping:true,
                  h:this.bindProp("Categorylenght2"),  
                  // h:(h)=>h,
 
                  rect:true,
                  color:0x00032226,
                  flex:{ direction: 'row'},
             ChannelWrapper: {
              w: 220,
              h: (h)=>h-60,
              zIndex: 2,
              rect: true,
              clipping: true,
              // autoResize: true,
              color:0x002E6D75, // 0xff495057, //0xff0D2B3D, //0xff035767, //0xff021212,
              // color:0xff032226,
              autoResize:true,
              Channels: {
                flexItem: { marginLeft: 8 },
                type: List,
                direction: 'column',
                spacing: 55,
                scroll:  { after:2 },    
                clipping: true,
                autoResize: true,
 
              },
             },
             ProgramWrapper: {
              x:15,
              // flexItem: { marginLeft: 8 },
              w: 1387,
              h: (h)=>h-60,
              zIndex: 2,
              rect: true,
              clipping: true,
              // autoResize: true,
              // color: 0xff2E6D75,//0xff495057,//  0xff0D2B3D, // Dark teal blue color,0xff021212,
              color:0x00032226,
              Programs: {
                clipping: true,
                spacing:55,
                direction: 'column',  
                type: List,
                autoResize: true,
                scroll: { after:2},    
   
                signals: { onIndexChanged: 'listIndexChanged' },
               
              },
             },
            },
        } 
        
  }
}
else{
  return {
    Grids:{
    w:(w)=>w,
    // h:600,
    h:this.bindProp("Categorylenght3"),
 
       collision:true,
      //  clipping:true,  
    // autoResize:true,
      // flexItem: { marginBottom: 8 ,grow: 1},
    IconWrapper:{
        w:(w)=>w,  
        h:60,  
        rect:true,
        type:Featured,
        // name:this.bindProp("Categoryname"),
        name:this.bindProp("Categorynamear"),
        titleImg:this.bindProp("CategoryImg"),
        xValue:this.bindProp("iconshiftar"),
        color:0x00032226,
        direction: 'column',  
        collision:true
        },
 
        Wrapper3:{
                  y:76,
                  w:(w)=>w,
                  collision:true,
               clipping:true,
                  h:this.bindProp("Categorylenght2"),  
                  // h:(h)=>h,
 
                  rect:true,
                  color:0x00032226,
                  flex:{ direction: 'row'},
             ChannelWrapper: {
              w: 220,
              h: (h)=>h-60,
              zIndex: 2,
              rect: true,
              clipping: true,
              // autoResize: true,
              color:0x002E6D75, // 0xff495057, //0xff0D2B3D, //0xff035767, //0xff021212,
              // color:0xff032226,
              autoResize:true,
              Channels: {
                flexItem: { marginLeft: 8 },
                type: List,
                direction: 'column',
                spacing: 55,
                scroll:  { after:2 },    
                clipping: true,
                autoResize: true,
 
              },
             },
             ProgramWrapper: {
              x:15,
              // flexItem: { marginLeft: 8 },
              w: 1387,
              h: (h)=>h-60,
              zIndex: 2,
              rect: true,
              clipping: true,
              // autoResize: true,
              // color: 0xff2E6D75,//0xff495057,//  0xff0D2B3D, // Dark teal blue color,0xff021212,
              color:0x00032226,
              Programs: {
                clipping: true,
                spacing:55,
                direction: 'column',  
                type: List,
                autoResize: true,
                scroll: { after:2} ,  
   
                signals: { onIndexChanged: 'listIndexChanged' },
               
              },
             },
            },
        }
  }
}
}
_init(){
  this.Categorylenght2=0  
  this.first=0
  this.flag88=0
  this.flag89=0
  this.secondProgIndex=0
}
set allCate(ac){
 
  this.ac=ac
}
set name(nameCate){
  this.Categoryname=nameCate
 
 
  let a=this.Categoryname.length
  this.iconshift=(a*11)+65      
}
set namear(nameCatear){
  this.Categorynamear=nameCatear
 
 
  let b=this.Categorynamear.length
  this.iconshiftar=(b*7)+65      
}
set wrapperid(wid){
  this.wrapperidepg=wid;
}
set len(l){
 
    this.Categorylenght=l
}
 
set titleImg(i){
    this.CategoryImg=i
}
set cd(movies3){
  this.movies=movies3
} 
 
async _setup() {
    let ChannelItems =[]
    let ProgramItems = []
this.flag=0;
    // let channelsDatanew = await logMovies()
    let channelsDatanew = this.movies//JSON.parse(localStorage.getItem('epg-channel-data'));
    let categoriesData =  await logCategories()
    let stopper=0
    let id=0
    let channelsData=channelsDatanew;
    channelsData.data.forEach(channel => {
        let categ2=channel.category;
        let capitalizedCate2 = categ2.charAt(0).toUpperCase() + categ2.slice(1);
                if(this.Categoryname==capitalizedCate2){
                  const dvrr = [channel.type , channel.events];
                
                  ProgramItems.push({ type: ProgramList5, number: id, dvrr, stopper, imageChannel:channel.images[4]}, );
                         
                  id++
                  if(stopper==0){
                    stopper=1;
                  }  
       
                  const imageUrls = [channel.images[4].url, channel.images[1].url];
                  if(imageUrls[0]=='' && imageUrls[1]!=''){
                    ChannelItems.push({ type: ChannelList, number: channel.id, name: imageUrls[1] });  
                  }else if(imageUrls[0]!=''){
                  ChannelItems.push({ type: ChannelList, number: channel.id, name: imageUrls[0]});
                  }
                  else{
                    ChannelItems.push({ type: ChannelList, number: channel.id, name: 'static/images/preview.png'});
                  }
                }
               
            // });
 
          })
         
         
         
          this.tag('Programs').add(ProgramItems)
          this.tag('Channels').add(ChannelItems)
       this.Categorylenght2=id*200
       if(this.Categorylenght2>400){
       this.Categorylenght3=400
       this.Categorylenght2=360
       }
           
          this.previousIndex = 0
          this.currIdx = 0
          this.positionX = 0
         
}

async listIndexChanged(obj) {

  // this.flag88=0  
  // this.fireAncestors('$gridscrollvalue',this.wrapperscroll);
  this.currIdx = obj.index
  if (this.currIdx >= this.previousIndex) {
    this.tag('Channels').next()
    // this.tag('TimeWrapper').next()
    this.previousIndex = this.currIdx
  } else {
    this.tag('Channels').previous()
    // this.tag('TimeWrapper').previous()
    this.previousIndex = this.currIdx
   
  } 
} 
 
_getFocused() {
    return this.tag('Programs');
}
 
static get width() {
    return 1463
  }
  static get height() {
    return 515
  }
 

 

 
$moveList8(index,objindx, liveprog) {
    let d=this.tag('Programs').items[index] 
    // this.positionX = scrollValue; 
   
    if(this.wrapperidepg==0 && this.flag==0){
    // this.fireAncestors('$scrollToTimeWrapper', scrollValue);
    // this.fireAncestors('$gridscrollvalue',scrollValue, this.wrapperidepg);
    // this.fireAncestors('$timegridscrollvalue',scrollValue);//new
    this.flag++ 
    }
    else if(this.wrapperidepg==0 && this.flag<this.Categorylenght && this.flag!=0){
      if(objindx==liveprog-1 || objindx==liveprog+2){ 
        this.flag88=2  
      }  
      this.fireAncestors('$checkNowNext',this.flag88); 
      this.flag++
    }
    if(this.wrapperidepg!=0 && this.flag==0){
      if(objindx==liveprog-1 || objindx==liveprog+2){ 
        this.flag88=2  
      }  
      
      // if(objindx>this.secondProgIndex){   
        // this.flag89++
        // this.fireAncestors('$checkNowNext',this.flag88);  
      // }
      // if(objindx<this.secondProgIndex){ 
      //   this.flag88-- 
      //   // this.fireAncestors('$checkNowNext',this.flag88); 
      // }   
      // this.fireAncestors('$scrollToTimeWrapper', scrollValue);
      // this.fireAncestors('$timegridscrollvalue',scrollValue);//new
      // if(objindx==liveprog-1){ 
      //   this.flag88=2  
      // }  
      // this.secondProgIndex=objindx
      // this.fireAncestors('$checkNowNext',this.flag88);
   
      // this.wrapperscroll=scrollValue
        // this.fireAncestors('$gridscrollvalue',this.wrapperscroll, this.wrapperidepg);
        // this.fireAncestors('$scrollToTimeWrapper', scrollValue);
      this.flag++
      }
      if(this.wrapperidepg!=0 && this.flag<this.Categorylenght && this.flag!=0){
       if(objindx==liveprog-1 || objindx==liveprog+2){ 
        this.flag88=2  
      }  
      this.fireAncestors('$checkNowNext',this.flag88);

      // this.fireAncestors('$gridscrollvalue',this.wrapperscroll);
      // this.fireAncestors('$scrollToTimeWrapper', scrollValue);
 
      this.flag++
    }
    else if(this.wrapperidepg!=0 && this.wrapperidepg>2 && this.flag<this.Categorylenght && this.flag!=0){
      
      // this.fireAncestors('$gridscrollvalue',this.wrapperscroll);
      // this.fireAncestors('$scrollToTimeWrapper', scrollValue);
 
      this.flag++
    } 
    else if(this.flag>=this.Categorylenght ){  
      
      // if(objindx>this.secondProgIndex){ 
      //   this.flag88++ 
      // }
      
      // if(objindx<this.secondProgIndex){ 
      //   this.flag88-- 
      // }   
      if(objindx==liveprog-1 || objindx==liveprog+2){ 
        this.flag88=2  
      }  
      // this.secondProgIndex=objindx
      this.tag('Grids').scroll= 0;    
      // this.fireAncestors('$gridscrollvalue',scrollValue, this.wrapperidepg)
      this.fireAncestors('$checkNowNext',this.flag88);

    } 
    // this.tag('Grids').scroll={after:2}   
  } 

    

  
} 
 


