import { Lightning, Utils,Language } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import TimeList1 from './components/TimeList1';
import DetailScreen from './components/DetailScreen';
import CategoryList from './components/CategoryList'; 
import TimeList from './components/TimeList';
import { Grid } from '@lightningjs/ui'
// import categories from './epg_category.json' 
import Epglist from './components/Epglist';
import Epglist1 from './components/Epglist1' 
import categories from './channels-category.json' 
import LiveList from './components/LiveList'
import Icons from "./icons.json";
import MenuList from './components/MenuList';
import MenuScreen1 from './components/MenuScreen1';


let timenoww=printTime1(); 
       function printTime1() {
         const now = new Date();
         let hours = now.getHours().toString().padStart(2, '0');
         let minutes = now.getMinutes().toString().padStart(2, '0');
         const timeString1 = `${hours}:${minutes}`;
         return timeString1;
       }
       setInterval(printTime1, 1000);

// const categories = ['All Categories', 'Live News', 'Features', 'Religious', 'Movies', 'Playlist', 'Lifestyle', 'Entertainment', 'Factual', 'Sports', 'Kids', 'Drama'];
const timings = ['05:00', '06:00', '07:00', '08:00', '09:00','10:00','11:00','12:00','13:00','14:00','15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00','23:00','00:00','01:00','02:00','03:00','04:00'];
const timings1 = ['5', '6', '7', '8', '9','10','11','12','13','14','15', '16', '17', '18', '19', '20', '21', '22','23','0','1','2','3','4'];
const timings2 = ['Now - ' + timenoww,'Next','Later'];
const timings3 = [timenoww+ ' - الآن','التالي','لاحقاً'];



async function logMovies() {
  const timestamps = getTimestamps();
  let language=  localStorage.getItem('language');  
  // const response = await fetch("https://aa703804-c244-4a72-8c0b-308fe8416659.mock.pstmn.io/demo"); //https://6421371c-f6dd-471f-a870-bd40feca6068.mock.pstmn.io/demo
  const response = await fetch(`https://sp-stg-new-api.aws.playco.com/api/v1.1/epg/category/events/92f791cb77094c049feaf5b71c332859-sp?category=live-news,featured,sports,religious,playlist,lifestyle,testing2,svod,avod,svodavod,regression-category,drama,kids,factual,testing,entertainment&ts_start=${timestamps.sixHoursBack}&ts_end=${timestamps.sixHoursAhead}&lang=${language}&profileType=EV&x-geo-country=AE&pg=18&page=1&limit=500`);
  let movies = await response.json();
 // localStorage.setItem('epg-channel-data',JSON.stringify(movies));
  return movies; 
} 

//-----------------------------------------
function getTimestamps() {
  const currentDate = new Date();
  const fiveAmCurrentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 5, 0, 0);
  const fiveAmTimestamp = Math.floor(fiveAmCurrentDate.getTime() / 1000);
  const fourAmNextDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1, 4, 0, 0);
  const fourAmTimestamp = Math.floor(fourAmNextDate.getTime() / 1000);
  return {
      current: currentDate,
      sixHoursBack: fiveAmTimestamp,
      sixHoursAhead: fourAmTimestamp
  };
}
//----------------------------------------

async function logMenu() {
  return Icons; 
} 


async function logCategories() {
  return categories;
}

// async function logMovies() {
//     return Movies; 
//   } 


export default class App extends Lightning.Component {
  static language() {
    return localStorage.getItem('language')  || 'en';
  }
  static getFonts() {
    return [{ family: 'Regular', url: Utils.asset('fonts/Roboto-Regular.ttf') },
      {family: 'RegularArabic', url: Utils.asset('fonts/FontsFree-Net-URW-DIN-Arabic-1.ttf')}
    ]
  }
 
  static _template() {
if(localStorage.getItem('language')=="en"){
    return {
      LoaderBg:{
        src: Utils.asset("./images/brand-background-main.png"),
        w: 1920, h:1800,
        zIndex:200,
       
      Loader: {
        w: 270, h: 150,
        x: 825, y:500,
        src: Utils.asset("./images/aa.png"), // Replace with the path to your loading GIF file
        visible: true, // Initially show the loader
        zIndex: 200,
        // UsingTexture:{
        //   x: 970,
        //   y: 580,
          // moutX:1,
          // mountY:1,
          // w: 50,
          // h: 50,
          // texture: {
          //   type: lng.textures.ImageTexture,
          //   src: Utils.asset("./images/loader.gif"),
          // },
   
        // },
    },
  },
      Wrapper:{
      // forceZIndexContext: true,
      visible:false,
      Background: {
        w: 1920, // Full screen width
        h: 1080, // Full screen height
        collision:true,
        defaultFontFace:"Poppins",
        BackgroundImg: {
          collision:true,
          w:(w)=>w,
          h:(h)=>h,
          x:0,
          y: 0,
          clipping:true,
          w: 1920, // Full screen width
          h: 1080, // Quarter of the screen height
        src: Utils.asset("./images/brand-background-main.png"),
       // rect: true,
       // color: 0xff021212, // Dark teal blue color
      }, 
      },
      MenuScreen: {
        x: 0,
        y: 0,
        h: 1080,//1080
        collision: true,
        // spacing: 8,
        direction: 'column',
        type: List,
        autoResize: true,
        // scroll: 0,
        zIndex:4,
        scroll: {
          after: 10,  
        },
        signals: { onIndexChanged: 'listIndexChanged' },
      },
      MenuScreen2: {
        x: 135, // Adjust the x position to place it next to the first MenuScreen
        y: 0,
        h: 1080,
        clipping: true,
        direction: 'column',
        type: List,
        autoResize: true, 
        scroll: 0,
        visible: true,
        zIndex: 3,
        // color: 0x00032226,  
        // alpha: 1,
        collision: true,
        signals: { onIndexChanged: 'listIndexChanged' },
      },
      DetailScreen: { // Add the DetailScreen component
        type: DetailScreen, movieObj: this.bindProp('movieObj'),channelObj:this.bindProp('channelObj'), movieImage:this.bindProp('movieImage')
      },
      Wrapper0: {
        x: 135, // Adjusted x position
        y: 510, // Adjusted y position
        w: 1870, // Width of the wrapper//1820
        h: 525, // Height of the wrapper
        flex: { direction: 'row' },
        clipping:true,

        CategoryWrapper: {
          w: 362,
          h: 576,
          zIndex: 2,
          rect: true,
          clipping: true,
          autoResize: true,
          color:0x000D2B3D,      //      0xff2E6D75, // 0xff495057, //0xff0D2B3D, //0xff035767, //0xff021212,
          // color:0xff032226,
          
          DayWrapper:{
            w:(w)=>w,
            h:60,
            rect:true,
            color:0x002b444a, 
            shader: {type: Lightning.shaders.RoundedRectangle, radius: [0, 0, 8, 8],stroke: 2,strokeColor: 0x33d1d3d4,},
            color:0x1affffff,
            Day: {
            mountX: -0.2,
            mountY: -0.3,
            y:5,
            text: {
              text: Language.translate('CategoryHeader'),//'CATEGORIES', // The text content
              fontFace: 'Regular', // The font family to use
              fontSize: 22, // The font size
              textColor: 0xffffffff, // The color of the text
              textAlign: 'left', // The alignment of the text
              textOverflow: 'ellipsis', // How text that overflows the component's bounds should be handled
              // lineHeight: 30, // The height of each line of text
              // lineSpacing: 5, // The spacing between lines of text
              autoResize: true,
          },
          shader: {
            type:  Lightning.shaders.RoundedRectangle, radius: [0, 0,0,0], stroke: 0,strokeColor:  0x88ffffff,
          }
          },
         },
         CategoryWrapper1:{
          y:75,
          w: (w)=>w,
          h: 550,
          clipping:true,
          Category: {
            flexItem: { marginLeft: 8 },
            // y: 77,
            w: 362,
            h: 55,
            type: List,
            direction: 'column',
            spacing: 10,
            // scroll:20, 
            scroll: {
              after: 7,  
              // forward: 0.6,  //unless last item: scroll forward if item bounds are after 90% of the List, or if value is above 1; scroll after f.e. 900 pixels
              // backward: 0.4, 
            },
            clipping: true,
            autoResize: true,
          },
        },
        },
        
        Wrapper1:{
          y:-55,  
          x:45,
          w: 1463, // Width of the wrapper
          h: (h)=>h,
          // zIndex: 2,
          rect: true,
          clipping: true,
          autoResize: true,
          color:0x00032226,
          flex: { direction: 'column' },
          
          NowWrapper:{
            x:235,  
            zIndex:10,
            w:1600,  
            h: 60,    
            y:55,    
            rect: true,
            clipping: true,
            autoResize: true,
            color:0x00032226,     
           NowWrapper1:{
            w: 1600,  
            h: 60,  
            x:0,
            y:0,
            type: List,
            direction: 'row',  
            spacing: 14,
            scroll: 0,
            autoResize: true,
            collision: true,  
            // visible:false,
          },
        },
       
      TimeWrapper2:{
            x:235,  
            // zIndex:9,
            w:1600,  
            h: 525,    
            y:55-60,     
            rect: true,
            clipping: true, 
            autoResize: true,
            color:0x00032226,    
            TimeWrapperOuter: {          
            // zIndex:10,
            w: 1600,  
            h: 60,  
            x:0,
            y:0,
            type: List,
            direction: 'row',  
            spacing: 14,
            scroll: 0,
            autoResize: true,
            collision: true,  
           },
          },
          TimeWrapper1:{
            y:-470-60, 
            flex:{ direction: 'row'},
            w: (w)=>w,
            h: 60,
            zIndex: 2,
            rect: true,
            // clipping: true,  
            autoResize: true,
            color:0x00032226,  
            // mountX: 0.5, // Align time text in the center
            // x: 735, // Align time text in the center
            // y: 20, // Adjusted y position
            // w: 1600, //1700
            //TODO: add grid
            // mountX: -0.25,
            // mountY: -0.25,
            // text: {
            //   text: '8:00',
            //   textAlign: 'left',
            //   fontFace: 'Regular',
            //   textColor: 0xffffffff,
            //   fontSize: 26,
            //   textOverflow: 'ellipsis',
            //   autoResize: true,
            // },
            DayWrapper1:{
              w:220,
              h:60, 
              rect:true,
              shader: {type:  Lightning.shaders.RoundedRectangle, radius: [0, 0, 8,8], stroke: 2 ,strokeColor: 0x33d1d3d4,},
              color: 0x1affffff,
 
              
             Day: {
              x:(w)=>w/2,
              y:(h)=>h/2+1, 
              mount:0.5,
              mountY: 0.38,
              text: {
                text: this.bindProp('todaysDate'), // The text content
                fontFace: 'Regular', // The font family to use
                fontSize: 24, // The font size
                textColor: 0xffffffff, // The color of the text
                textAlign: 'left', // The alignment of the text
                textOverflow: 'ellipsis', // How text that overflows the component's bounds should be handled
                lineHeight: 30, // The height of each line of text
                lineSpacing: 5, // The spacing between lines of text
                autoResize: true,
             },
             shader: {
              type:  Lightning.shaders.RoundedRectangle, radius: [0, 0,0,0], stroke: 0,strokeColor:  0x88ffffff,
            }
            },
            },
              
              TimeWrapper: {
              clipping:true,
              x:15,
              // zIndex: 2,
              // flexItem: { marginLeft: 8 },
              w: (w)=>w-220,
              h: (h)=>h,
              type: List,
              direction: 'row',
              spacing: 14,
              scroll: 0,
              autoResize: true,
              collision: true
             },

            
         },
         Wrapper2:{
          
          // flex:{ direction: 'column'},
            y:-475-60, 
            w: (w)=>w,
            h: 515,     
            zIndex: 2, 
            // rect: true,
            collision:true,
            clipping: true, 
            autoResize: true,
            color:0x00032226, 
            type: Grid,
            direction: 'column',
            spacing: 10,
            scroll:10,        
            signals: { onIndexChanged: 'wrapperIndexChanged' }, 
            flex: { direction: 'column',}, 
        
        }, 

       
        },
      },
    },
    }
  }
  else{
    return {
      LoaderBg:{
        src: Utils.asset("./images/brand-background-main.png"),
        w: 1920, h:1800,
        zIndex:200,
       
      Loader: {
        w: 270, h: 150,
        x: 825, y:500,
        src: Utils.asset("./images/aa.png"), // Replace with the path to your loading GIF file
        visible: true, // Initially show the loader
        zIndex: 200,

    },
  },
      Wrapper:{
      // forceZIndexContext: true,
      visible:false,
      Background: {
        w: 1920, // Full screen width
        h: 1080, // Full screen height
        collision:true,
        defaultFontFace:"Poppins",
        BackgroundImg: {
          collision:true,
          w:(w)=>w,
          h:(h)=>h,
          x:0,
          y: 0,
          clipping:true,
          w: 1920, // Full screen width
          h: 1080, // Quarter of the screen height
        src: Utils.asset("./images/brand-background-main.png"),
       // rect: true,
       // color: 0xff021212, // Dark teal blue color
      }, 
      },
      MenuScreen: {
        x:0, 
        y: 0,
        h: 1080,//1080
        collision: true,
        // spacing: 8,
        direction: 'column',
        type: List,
        autoResize: true,
        // scroll: 0,
        zIndex:4,
        scroll: {
          after: 10,  
        },
        signals: { onIndexChanged: 'listIndexChanged' },
      },
      MenuScreen2: {
        x: 135, // Adjust the x position to place it next to the first MenuScreen
        y: 0,
        h: 1080,
        clipping: true,
        direction: 'column',
        type: List,
        autoResize: true, 
        scroll: 0,
        visible: true,
        zIndex: 3,
        // color: 0x00032226,  
        // alpha: 1,
        collision: true,
        signals: { onIndexChanged: 'listIndexChanged' },
      },
      DetailScreen: { // Add the DetailScreen component
        type: DetailScreen, movieObj: this.bindProp('movieObj'),channelObj:this.bindProp('channelObj'), movieImage:this.bindProp('movieImage')
      },
      Wrapper0: {
        x: 135, // Adjusted x position
        y: 510, // Adjusted y position
        w: 1870, // Width of the wrapper//1820
        h: 525, // Height of the wrapper
        flex: { direction: 'row' },
        clipping:true,

        CategoryWrapper: {
          w: 362,
          h: 576,
          zIndex: 2,
          rect: true,
          clipping: true,
          autoResize: true,
          color:0x000D2B3D,      //      0xff2E6D75, // 0xff495057, //0xff0D2B3D, //0xff035767, //0xff021212,
          // color:0xff032226,
          
          DayWrapper:{
            w:(w)=>w,
            h:60,
            rect:true,
            color:0x002b444a, 
            shader: {type: Lightning.shaders.RoundedRectangle, radius: [0, 0, 8, 8],stroke: 2,strokeColor: 0x33d1d3d4,},
            color:0x1affffff,
            Day: {
            mountX: -0.2,
            mountY: -0.3,
            y:5,x:15,
            text: {
              text: Language.translate('CategoryHeader'), // The text content
              fontFace: 'Regular', // The font family to use
              fontSize: 28, // The font size
              textColor: 0xffffffff, // The color of the text
              textAlign: 'left', // The alignment of the text
              textOverflow: 'ellipsis', // How text that overflows the component's bounds should be handled
              // lineHeight: 30, // The height of each line of text
              // lineSpacing: 5, // The spacing between lines of text
              autoResize: true,
          },
          
          shader: {
            type:  Lightning.shaders.RoundedRectangle, radius: [0, 0,0,0], stroke: 0,strokeColor:  0x88ffffff,
          },
          shader: {type: Lightning.shaders.Perspective, rx: 3.14}, 
          },
         },
         CategoryWrapper1:{
          y:75,
          w: (w)=>w,
          h: 550,
          clipping:true,
          Category: {
            flexItem: { marginLeft: 8 },
            // y: 77,
            w: 362,
            h: 55,
            type: List,
            direction: 'column',
            spacing: 10,
            // scroll:20, 
            scroll: {
              after: 7,  
              // forward: 0.6,  //unless last item: scroll forward if item bounds are after 90% of the List, or if value is above 1; scroll after f.e. 900 pixels
              // backward: 0.4, 
            },
            clipping: true,
            autoResize: true,
          },
        },
        },
        
        Wrapper1:{
          y:-55,  
          x:45,
          w: 1463, // Width of the wrapper
          h: (h)=>h,
          // zIndex: 2,
          rect: true,
          clipping: true,
          autoResize: true,
          color:0x00032226,
          flex: { direction: 'column' },
          
          NowWrapper:{
            x:235,  
            zIndex:10,
            w:1600,  
            h: 60,    
            y:55,    
            rect: true,
            clipping: true,
            autoResize: true,
            color:0x00032226,     
           NowWrapper1:{
            w: 1600,  
            h: 60,  
            x:0,
            y:0,
            type: List,
            direction: 'row',  
            spacing: 14,
            scroll: 0,
            autoResize: true,
            collision: true,  
            // visible:false,
          },
        },
       
      TimeWrapper2:{
            x:235,  
            // zIndex:9,
            w:1600,  
            h: 525,    
            y:55-60,     
            rect: true,
            clipping: true, 
            autoResize: true,
            color:0x00032226,    
            TimeWrapperOuter: {          
            // zIndex:10,
            w: 1600,  
            h: 60,  
            x:0,
            y:0,
            type: List,
            direction: 'row',  
            spacing: 14,
            scroll: 0,
            autoResize: true,
            collision: true,  
           },
          },
          TimeWrapper1:{
            y:-470-60, 
            flex:{ direction: 'row'},
            w: (w)=>w,
            h: 60,
            zIndex: 2,
            rect: true,
            // clipping: true,  
            autoResize: true,
            color:0x00032226,  
            // mountX: 0.5, // Align time text in the center
            // x: 735, // Align time text in the center
            // y: 20, // Adjusted y position
            // w: 1600, //1700
            //TODO: add grid
            // mountX: -0.25,
            // mountY: -0.25,
            // text: {
            //   text: '8:00',
            //   textAlign: 'left',
            //   fontFace: 'Regular',
            //   textColor: 0xffffffff,
            //   fontSize: 26,
            //   textOverflow: 'ellipsis',
            //   autoResize: true,
            // },
            DayWrapper1:{
              w:220,
              h:60, 
              rect:true,
              shader: {type:  Lightning.shaders.RoundedRectangle, radius: [0, 0, 8,8], stroke: 2 ,strokeColor: 0x33d1d3d4,},
              color: 0x1affffff,
 
              
             Day: {
              x:(w)=>w/2,
              y:(h)=>h/2+1, 
              mount:0.5,
              mountY: 0.38,
              text: {
                text: this.bindProp('todaysDate'), // The text content
                fontFace: 'Regular', // The font family to use
                fontSize: 24, // The font size
                textColor: 0xffffffff, // The color of the text
                textAlign: 'left', // The alignment of the text
                textOverflow: 'ellipsis', // How text that overflows the component's bounds should be handled
                lineHeight: 30, // The height of each line of text
                lineSpacing: 5, // The spacing between lines of text
                autoResize: true,
             },
             shader: {
              type:  Lightning.shaders.RoundedRectangle, radius: [0, 0,0,0], stroke: 0,strokeColor:  0x88ffffff,
            },  shader: {type: Lightning.shaders.Perspective, rx: 3.14},
            },
            },
              
              TimeWrapper: {
              clipping:true,
              x:15,
              // zIndex: 2,
              // flexItem: { marginLeft: 8 },
              w: (w)=>w-220,
              h: (h)=>h,
              type: List,
              direction: 'row',
              spacing: 14,
              scroll: 0,
              autoResize: true,
              collision: true
             },

            
         },
         Wrapper2:{
          
          // flex:{ direction: 'column'},
            y:-475-60, 
            w: (w)=>w,
            h: 515,     
            zIndex: 2, 
            // rect: true,
            collision:true,
            clipping: true, 
            autoResize: true,
            color:0x00032226, 
            type: Grid,
            direction: 'column',
            spacing: 10,
            scroll:10,        
            signals: { onIndexChanged: 'wrapperIndexChanged' }, 
            flex: { direction: 'column',}, 
        
        }, 

       
        },
      },
    },
    }
  }
  }

  _construct(){
   if( localStorage.getItem("language")==null){
    localStorage.setItem('language',"en"); 
    this.stage.application.__keymap={8: 'Back', 13: 'Enter', 27: 'Exit', 37: 'Left', 38: 'Up', 39: 'Right', 40: 'Down', 174: 'ChannelDown', 175: 'ChannelUp', 178: 'Stop', 191: 'Search', 250: 'PlayPause', 409: 'Search'}
   }
   if(localStorage.getItem('language')=="ar"){
    this.mainbody = document.body;
    this.mainbody.style.transform = "rotateY(180deg)" 
    document.body.setAttribute('dir', 'rtl');
    this.stage.application.__keymap={8: 'Back', 13: 'Enter', 27: 'Exit', 37: 'Right', 38: 'Up', 39: 'Left', 40: 'Down', 174: 'ChannelDown', 175: 'ChannelUp', 178: 'Stop', 191: 'Search', 250: 'PlayPause', 409: 'Search'}
   }
   if(localStorage.getItem('language')=="en"){
    this.stage.application.__keymap={8: 'Back', 13: 'Enter', 27: 'Exit', 37: 'Left', 38: 'Up', 39: 'Right', 40: 'Down', 174: 'ChannelDown', 175: 'ChannelUp', 178: 'Stop', 191: 'Search', 250: 'PlayPause', 409: 'Search'}
   } 
  }	
generateArray(programCount) {
    let arr = [];
    for (let i = 0; i < programCount; i++) {
        arr.push(this.getRandom(10, 180) * 3);
    }
    return arr;
}

_init(){
  this.distances=0; 
  this.timenow=0; 
}

async _setup() {
  this._spinImage();
  this.flag9=true;
  let epgFlag = 0;
  this.flag=0
  this.first=0
   // let myarray=[]
   const TimeItems = []
   const TimeItems1=[]
   let EpgItem=[]
   let EpgItem1=[]
   // let ProgramItems = []
   const CategoryItems = []
   const NowNextItems=[]
   const MenuItems = []
   // let ChannelItems =[]
   // const LineItems=[]
   // let CategoryTitle=[]
   let wrapperid=0
   // let stopper=0
   CategoryItems.push({ type: CategoryList, name: "All Categories", namear: "جميع الفئات", toggled:true });
let channelsDatanew = await logMovies()
let categoriesData =  await logCategories() 
 
let categorynumber=0
   categoriesData.forEach(Category => {
     let categ=Category.slug
     let categar=Category.slug_ar 
     let capitalizedCate = categ.charAt(0).toUpperCase() + categ.slice(1);
     categorynumber++
     CategoryItems.push({ type: CategoryList, name: capitalizedCate,namear:categar , toggled: false});
   })
   this.totalCategories=categorynumber 
for(let i=0;i<categorynumber;i++){
  this.positionXYZ[i] = 0;
}
if(channelsDatanew){
  this.tag('Wrapper').patch({ visible: true })
    this.tag('LoaderBg').patch({ visible: false })
    let playerDiv = document.getElementById('videoDiv');
    // playerDiv.style.visibility="hidden";
    // playerDiv.style.visibility="visible";
}
let menuData = await logMenu()
this.movieObj={'movie':channelsDatanew.data[0].events[0], 'type':channelsDatanew.data[0].type }; 

  this.channelObj=channelsDatanew.data[0].images[4];
  this.movieImage=channelsDatanew.data[0].events[0].images[0].url
   let channelsData=channelsDatanew;
let WrapperLength=0

   categoriesData.forEach(Category => {
            let categ=Category.slug
            let categar=Category.slug_ar
            let capitalizedCate = categ.charAt(0).toUpperCase() + categ.slice(1);
            channelsData.data.forEach(channel => {
            let categ2=channel.category;
            let capitalizedCate2 = categ2.charAt(0).toUpperCase() + categ2.slice(1);
           if(capitalizedCate==capitalizedCate2){
             WrapperLength++
           }
           
         })  
         if(WrapperLength!=0){
           this.Wrapperlength=WrapperLength*200
           if(this.Wrapperlength>400){
             this.Wrapperlength=400
           }
            
           EpgItem.push({ type: Epglist,cd:channelsDatanew,h:this.Wrapperlength ,name: capitalizedCate,namear:categar, titleImg:Category.icon.png , len:WrapperLength,wrapperid,allCate:true},);
           EpgItem1.push({ type: Epglist1,cd:channelsDatanew,h:this.Wrapperlength ,name: capitalizedCate,namear:categar, titleImg:Category.icon.png , len:WrapperLength,wrapperid,allCate:true},);
          
           if(epgFlag==0){
            this.tag("Wrapper2").add(EpgItem1)   
            epgFlag++
           }else{ 
            this.tag("Wrapper2").reload(EpgItem1)  
            } 
            this.EpgItem=EpgItem
            this.EpgItem1=EpgItem1 
            wrapperid++
          }
          WrapperLength=0
})

//  categoriesData.forEach(Category => {
//             let categ=Category.slug
//             let capitalizedCate = categ.charAt(0).toUpperCase() + categ.slice(1);
//             CategoryTitle.push({ type: Featured, name: capitalizedCate, titleImg:Category.icon.png});
//             this.tag('IconWrapper').add(CategoryTitle)  
//             CategoryTitle=[]
//        channelsData.data.forEach(channel => {
//         let categ2=channel.category;
//   let capitalizedCate2 = categ2.charAt(0).toUpperCase() + categ2.slice(1);
//         if(capitalizedCate==capitalizedCate2){
//           const dvrr = channel.events;
//           ProgramItems.push({ type: ProgramList, number: channel.id, dvrr, stopper, imageChannel:channel.images[2]});
//           if(stopper==0){
//             stopper=1;
//           }  

//           const imageUrls = [channel.images[2].url, channel.images[1].url];
//           if(imageUrls[0]=='' && imageUrls[1]!=''){
//             ChannelItems.push({ type: ChannelList, number: channel.id, name: imageUrls[1] });  
//           }else if(imageUrls[0]!=''){
//           ChannelItems.push({ type: ChannelList, number: channel.id, name: imageUrls[0]});
//           }
//           else{
//             ChannelItems.push({ type: ChannelList, number: channel.id, name: 'static/images/preview.png'});
//           }
//         }
      
//     // });
  

//     this.tag('Programs').add(ProgramItems)
//     this.tag('Channels').add(ChannelItems)

//     ProgramItems = []
//     ChannelItems =[]



//   })
// })



   menuData.data.forEach(menu => {
    const iconUrl = menu.src;
    const iconUrl1 = menu.src1;
    const iconname= menu.title;
    MenuItems.push({ type: MenuList, name: iconUrl, name1: iconUrl1, name2:iconname});
  });
let menuTitles2 =[]
  if(localStorage.getItem('language')=="en"){
   menuTitles2 = Icons.data.map(icon => ({
    type: MenuScreen1,
    name: icon.title,
 }));
}
else{
   menuTitles2 = Icons.data.map(icon => ({
    type: MenuScreen1,
    name: icon.titlear,
 }));
}
 this.tag('MenuScreen2').add(menuTitles2);
 this.tag('MenuScreen').add(MenuItems)


   // lines.forEach(l=>{
   //   LineItems.push({type:LiveList, name: l});
   // })
  
  


     this.on('$gridIndexChanged', (index) => {
       this.currentGridIndex = index;
    });
   //  this.tag('IconWrapper').add(CategoryTitle)
   //  this.tag('Programs').add(ProgramItems)
 this.catearray=CategoryItems
 this.tag('Category').add(CategoryItems)

   const timearray1=timings1
 const timearray=timings
this.tg=timings
this.tg1=timearray1
let timearray2=[] 
   
if(localStorage.getItem('language')=="en"){
 timearray2=timings2
}
else{
 timearray2=timings3   
}
let statusOfNowNext=false;    
NowNextItems.push({ type: LiveList, timearray2});  
this.NowNextItems=NowNextItems 

TimeItems1.push({ type: TimeList1, timearray1, });
TimeItems.push({ type: TimeList, timearray, });
this.TimeItems=TimeItems
this.TimeItems1=TimeItems1


  //  this.tag('TimeWrapperOuter').add(TimeItems1)  
  //  this.tag('TimeWrapper').add(TimeItems)
   this.tag('NowWrapper1').add(NowNextItems)
   this.previousIndex = 0
   this.currIdx = 0
   this.positionX = 0

   let currentTime =0;
       currentTime= printTime();
       let timenoww=printTime1();
       // currentTime=50;

       function printTime() {
       const now = new Date();
       let hours = now.getHours().toString().padStart(2, '0');
       let minutes = now.getMinutes().toString().padStart(2, '0');

        const timeString = (`${hours}`-5)*60+`${minutes}`;    

       return timeString;
       }

       function printTime1() {
         const now = new Date();
         let hours = now.getHours().toString().padStart(2, '0');
         let minutes = now.getMinutes().toString().padStart(2, '0');
      
         const timeString1 = `${hours}:${minutes}`;
      
         return timeString1;

       }
      
      
       setInterval(printTime1, 1000);


       setInterval(printTime, 1000);

       let distance=0;
       distance=615/60;
       distance=distance*currentTime;
       this.distances=distance+235;
       this.timenow=timenoww;
 }






 $timegridscrollvalue(scrollValue){  //new
  this.timelog=scrollValue
}

$scrollToTimeWrapper3() {  //new
  
  let scrollValue= this.timelog
  this.tag('TimeWrapperOuter').items.map((d) => {
    if (d.isAlive) {
        d.tag('Grids')._scrollTransition.start(-scrollValue);
    }
});  
}
  // Method to handle grid index changes
  $gridIndexChanged1(indexNum) {
     // Update the class property with the new indexNum
     this.currentIndexNum = indexNum;
  }
  $gridIndexChanged2(indexNum) { 
    // Update the class property with the new indexNum
    this.currentIndexNum = indexNum;
 }

 $gridIndexChanged3(indexNum) { 
  this.currentIndexNum2 = indexNum;
} 

async wrapperIndexChanged(obj) {
  this.tag('TimeWrapperOuter').items.map((d) => {
   
        d.tag('Grids')._scrollTransition.start(-this.positionXYZ[obj.index] );
   
});   
  this.tag('TimeWrapper').items.map((d) => {
     
          d.tag('Grids')._scrollTransition.start(-this.positionXYZ[obj.index] );  
     
  });//LiveLine
} 

     

async $enteredCategory(cate_Name){
  let categoriesData =  await logCategories()
  let wrapperid=0
  let categar="" 
let epgFlag=0
let EpgItem=[]
let EpgItem1=[] 
let imgofCate="" 
  let channelsDatanew = await logMovies()
  let channelsData=channelsDatanew;
  this.tag('Wrapper2').clear(); 
  if(cate_Name!="All Categories"){
    let WrapperLength=0
    categoriesData.forEach(Category => {
      let categ=Category.slug
  
      let capitalizedCate2 = categ.charAt(0).toUpperCase() + categ.slice(1);
      if(capitalizedCate2==cate_Name){
        categar=Category.slug_ar 
        imgofCate=Category.icon.png
          WrapperLength++ 
      }
    //   channelsData.data.forEach(channel => {
    //     let categ2=channel.category;  
    //     let capitalizedCate = categ2.charAt(0).toUpperCase() + categ2.slice(1);
    //    if(capitalizedCate==cate_Name){ 
    //      WrapperLength++  
    //    }   
    //  })  
    })

      this.Wrapperlength=440
    

      EpgItem.push({ type: Epglist,cd:channelsDatanew,h:this.Wrapperlength ,name: cate_Name,namear:categar, titleImg:imgofCate , len:3,wrapperid,allCate:false},);
      EpgItem1.push({ type: Epglist1,cd:channelsDatanew,h:this.Wrapperlength ,name: cate_Name,namear:categar, titleImg:imgofCate , len:3,wrapperid,allCate:false},);
         
      if(epgFlag==0){
        this.tag("Wrapper2").add(EpgItem1)   
        epgFlag++
       }else{  
        this.tag("Wrapper2").reload(EpgItem1)  
        }  
        this.EpgItem=EpgItem 
        this.EpgItem1=EpgItem1 
        wrapperid++ 
  }


if(cate_Name=="All Categories")
{
let WrapperLength=0

categoriesData.forEach(Category => {
  let categ=Category.slug
  let categar=Category.slug_ar
  let capitalizedCate = categ.charAt(0).toUpperCase() + categ.slice(1);
  channelsData.data.forEach(channel => {
  let categ2=channel.category;
  let capitalizedCate2 = categ2.charAt(0).toUpperCase() + categ2.slice(1);
 if(capitalizedCate==capitalizedCate2){
   WrapperLength++
 }
})   
if(WrapperLength!=0){
 this.Wrapperlength=WrapperLength*200
 if(this.Wrapperlength>400){
   this.Wrapperlength=400
 }
  
 EpgItem.push({ type: Epglist,cd:channelsDatanew,allCate:true,h:this.Wrapperlength ,name: capitalizedCate,namear:categar, titleImg:Category.icon.png , len:WrapperLength,wrapperid,},);
 EpgItem1.push({ type: Epglist1,cd:channelsDatanew,h:this.Wrapperlength ,name: capitalizedCate,namear:categar, titleImg:Category.icon.png , len:WrapperLength,wrapperid,allCate:true},);

if(epgFlag==0){
this.tag("Wrapper2").add(EpgItem1)   
epgFlag++
}else{ 
this.tag("Wrapper2").reload(EpgItem1)  
} 
this.EpgItem=EpgItem
this.EpgItem1=EpgItem1 
 wrapperid++
}

WrapperLength=0
})



  //  ProgramItems.push({ type: ProgramList, number: channel.id, dvrr, stopper, imageChannel:channel.images[2], channelIdx});
  //  this.channelIndex++;
  //  if(stopper==0){
  //   stopper=1;
  //  }
  //  const imageUrls = [channel.images[2].url, channel.images[1].url];
  //     if(imageUrls[0]=='' && imageUrls[1]!=''){
  //       ChannelItems.push({ type: ChannelList, number: channel.id, name: imageUrls[1] });  
  //     }else if(imageUrls[0]!=''){
  //     ChannelItems.push({ type: ChannelList, number: channel.id, name: imageUrls[0]});
  //     }
  //     else{
  //       ChannelItems.push({ type: ChannelList, number: channel.id, name: 'static/images/preview.png'});
  //     }
  }    



//  this.tag('Channels').clear()
//  this.tag('Programs').clear()
//  this.tag('Channels').add(ChannelItems)
//  this.tag('Programs').add(ProgramItems)
this.tag('TimeWrapper').clear(this.TimeItems) 
this.tag('TimeWrapperOuter').clear(this.TimeItems1)   
this.tag('NowWrapper1').add(this.NowNextItems)    



 
}

  $checkNowNext(num){
    this.flag88=num
     
  if(this.flag88==2){      
    this.tag('NowWrapper1').clear(this.NowNextItems)   
    this.tag("Wrapper2").clear()        
    this.tag("Wrapper2").add(this.EpgItem) 
    this.tag('TimeWrapper').add(this.TimeItems) 
    this.tag('TimeWrapperOuter').add(this.TimeItems1)   
  }  
  }
   


  $forDetailScreenObj(v,c,t){
    this.movieObj={'movie':v, 'type':t};  
 
    const imageUrls = [this.movieObj.movie.images[0].url, 'static/images/preview.jpg'];
    if(this.movieObj.movie.images[0].url){
      if (this.movieObj.movie.images[0].url.startsWith('http://')) {
          this.movieObj.movie.images[0].url= 'https://' + url.slice(7);
      }  
      }
    if(imageUrls[0]!=''){
      this.movieImage=imageUrls[0]  
    }else{
      this.movieImage=imageUrls[1]  
    }
    this.channelObj=c
    this.progc=c;
    this.progv=v;
    this.progt=t;
  }

  $forDetailScreenObj3(v,c,t){
    if(this.flagforDS==0){
      this.movieObj={'movie':v, 'type':t};   
   

    const imageUrls = [this.movieObj.movie.images[0].url, 'static/images/preview.jpg'];
    console.log("Henna",this.movieObj.movie.images[0].url);
    // if(this.movieObj.movie.images[0].url){
    //   if (this.movieObj.movie.images[0].url.startsWith('http://')) {
    //       this.movieObj.movie.images[0].url= 'https://' + url.slice(7);
          
    //   }  
    //   }
    if(this.movieObj.movie.images[0].url){
    if (this.movieObj.movie.images[0].url.startsWith('http://')) {
    this.movieObj.movie.images[0].url = this.movieObj.movie.images[0].url.replace('http://', 'https://');
    }}
    if(imageUrls[0]!=''){
      this.movieImage=imageUrls[0]  
    }else{
      this.movieImage=imageUrls[1]  
    }
    
    this.channelObj=c
    this.progc=c;
    this.progv=v;
    this.flagforDS=1
    this.progt=t;
  } 
  }
 
 

  $scrollToTimeWrapper(scrollValue) {
    this.tag('TimeWrapperOuter').items.map((d) => {
      if (d.isAlive) {
          d.tag('Grids')._scrollTransition.start(-scrollValue);
      }
  });  
    this.tag('TimeWrapper').items.map((d) => {
        if (d.isAlive) {
            d.tag('Grids')._scrollTransition.start(-scrollValue);
        }
    });//LiveLine
    
    this.positionX = scrollValue;
}


  $enteredCategoryreset(obje) { 
    let i=0
    let j=0
    this.catearray.forEach(Category => {
      Category.toggled=false;
      if(Category.name==obje)
      {
        Category.toggled=true;
        j=i
      }
      i++
    })
    
    this.tag("Category").reload( this.catearray)
    this.tag('Category').setIndex(j,"immediate")    
  }  

  async wrapperIndexChanged(obj) {
    this.tag('TimeWrapperOuter').items.map((d) => {
     
          d.tag('Grids')._scrollTransition.start(-this.positionXYZ[obj.index] );
     
  });  
    this.tag('TimeWrapper').items.map((d) => {
       
            d.tag('Grids')._scrollTransition.start(-this.positionXYZ[obj.index] );  
       
    });//LiveLine
  } 

  $gridscrollvalue(wrapperscroll,index){
    if(this.positionXYZ.length<this.totalCategories && this.positionXYZ[index]==null){
    this.positionXYZ.push(wrapperscroll);
  } 
  else if(this.positionXYZ.length<this.totalCategories && this.positionXYZ[index]!=null){   //new
    this.positionXYZ[index]=wrapperscroll                                //
  }        
}

$forDetailScreenObj(v,c,t){
  this.movieObj={'movie':v, 'type':t};  

  const imageUrls = [this.movieObj.movie.images[0].url, 'static/images/preview.jpg'];
  if(imageUrls[0]!=''){
    this.movieImage=imageUrls[0]  
  }else{
    this.movieImage=imageUrls[1]  
  }
  this.channelObj=c
  this.progc=c;
  this.progv=v;
  this.progt=t;

}

$forDetailScreenObj2(v,c,t){
  if(this.first==0){
    this.movieObj={'movie':v, 'type':t};  

  const imageUrls = [this.movieObj.movie.images[0].url, 'static/images/preview.jpg'];
  if(imageUrls[0]!=''){
    this.movieImage=imageUrls[0]  
  }else{
    this.movieImage=imageUrls[1]  
  }
  this.channelObj=c
  this.progc=c;
  this.progv=v;
  this.progt=t;
  this.first=1
}
}

 



  getRandom(min, max) {
    const floatRandom = Math.random()
    const difference = max - min
    // random between 0 and the difference
    const random = Math.round(difference * floatRandom)
    const randomWithinRange = random + min
    return randomWithinRange
  }

  static _states() {
    return [
      
        class CategoryFocus extends this {
            _getFocused() {
                return this.tag('Category');
            }
            _handleLeft() {
              this._setState('MenuFocus');
            
            }
            _handleRight() {
                this._setState('ProgramFocus');
            }
            _handleClick(){ 
              // return false
            }
            registerClickHandler(clickHandler) {
              this._clickListener = e => {
                  clickHandler(e);
              };
              window.addEventListener('mousedown', this._clickListener);
            }

        },

        class ProgramFocus extends this {
            _getFocused() {
                return this.tag('Wrapper2');
            }
            _handleLeft() {
              if(this.currentIndexNum <= 0){
                this._setState('CategoryFocus');
              }else{
              }
              
               }
        },
        class MenuFocus extends this {
          _getFocused() {
              return this.tag('MenuScreen');
             
          }
          _handleLeft() {
              this._setState('ProgramFocus');
          }
          _handleRight() {
            this._setState('CategoryFocus');
           
            }
          },
          class MenuSceenFocus extends this {
            _getFocused() {
                return this.tag('MenuScreen2');
            }
            _handleLeft() {
                this._setState('MenuFocus');
            }
            _handleRight() {
              this._setState('CategoryFocus');
              }
            },
    ];
  }


  _init() {
    this.flagforDS=0
    this.positionXYZ=[];
    this.todaysDate= getTodayDate();
    this.progv={}
    this.progc={}
    this.movieObj={'movie':{}, 'type':""};   
    this.channelIndex=0 
    this._setState('MenuFocus'); 
  

    this._sidePanelAnimation = this.tag('MenuScreen2').animation({
      duration: 5,
      repeat: 0,
   
      actions: [
          { p: 'x', v: { 0: { v: 70 }, 1: { v: -1000 } } }, // Initial state to final state for horizontal movement
          { p: 'scaleX', v: { 0: { v: 1 }, 0.5: { v: 0 }, 1: { v: 0 } } }, // Initial state to final state for scale
      ]
    });
    this._sidePanelAnimation.start();
    
  }
  _spinImage() {
    this.tag('Loader').animation({
        duration: 0.5,
        repeat: -1,
        actions: [
            { p: 'rotation', v: { 0: 0, 1: Math.PI * 2, sm: 0.5, b: 0.5, e: 'linear' } }
        ]
    }).start();
  }
}

function getTodayDate() {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = today.getMonth(); // Months are zero-indexed
  const yyyy = today.getFullYear();
 
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
 
  const formattedDate = `${dd} ${monthNames[mm]}, ${yyyy}`;
  return formattedDate;
}
 
