import { Lightning } from '@lightningjs/sdk';
 
import { Registry } from '@lightningjs/sdk'
export default class CategoryList extends Lightning.Component {
 
static _template() {
  if(localStorage.getItem('language')=="en"){
  return {
    Wrapper: {
      w: (w) => w,
      h: 70,//(h) => h,
      x:5,
      collision:true,
      rect: true,      
      color:0x00ffffff,// Dark teal blue color,0xffff2323,
     
       Text: {
        h:50,
        mountY: 0.5, // Center the text both horizontally and vertically
        y: 35, // Center the text vertically
        x: 20, // Center the text horizontally
       alpha:1,
        text: {
          textColor:0xffffffff,  
          text: this.bindProp('name'),
          zindex:99,
          fontFace: 'Regular',
          fontSize: 26,
          textAlign: 'left', // Ensure text is center-aligned          
        },
   
      },
    },
    HighLight: {
      w: 300,
      reuseInstance: false,
      h: 50 ,
      x:5,
      // alpha: 1,
      collision:true,
      rect: true,
     
      shader: {
        type:  Lightning.shaders.RoundedRectangle, radius: [25, 25, 25,25], zindex:1,
         stroke: 2,
         strokeColor: 0xffffffff,
       
        fillColor:0X33000000,
       
      },
      Text: {
        h:50,
        mountY: 0.5, // Center the text both horizontally and vertically
        y:35, // Center the text vertically
        x: 20, // Center the text horizontally
       alpha:1,
        text: {
          textColor:0xffffffff,  
          text: this.bindProp('name'),
          zindex:99,
          fontFace: 'Regular',
          fontSize: 26,
          textAlign: 'left', // Ensure text is center-aligned          
        },
        shader: {
          type:  Lightning.shaders.RoundedRectangle, radius: [0, 0,0,0], stroke: 0,strokeColor:  0x88ffffff,
        }
 
      },
     
      visible:  this.bindProp("toggled"),
   
    },
 
    HighLight1: {
      w: 300,
      h: 50 ,
      x:5,
 
      // alpha: 1,
      collision:true,
      rect: true,
     
      shader: {
        type:  Lightning.shaders.RoundedRectangle, radius: [25, 25, 25,25], zindex:1,
         stroke: 2,
         strokeColor: 0xFFd2ff00,
       
        fillColor:0xFFd2ff00,
       
      },
      Text: {
        h:50,
        mountY: 0.5, // Center the text both horizontally and vertically
        y:35, // Center the text vertically
        x: 20, // Center the text horizontally
       alpha:1,
        text: {
          textColor:0xff000000,  
          text: this.bindProp('name'),
          zindex:99,
          fontFace: 'Regular',
          fontSize: 26,
          textAlign: 'left', // Ensure text is center-aligned          
        },
        shader: {
          type:  Lightning.shaders.RoundedRectangle, radius: [0, 0,0,0], stroke: 0,strokeColor:  0x88ffffff,
        }
 
      },
     
      visible: false,
   
    },
 
 
 
  }
}
else{
  return {
    Wrapper: {
      w: (w) => w, 
      h: 70,//(h) => h,
      x:5,
      collision:true,  
     
      rect: true,      
      color:0x00ffffff,// Dark teal blue color,0xffff2323,
     
       Text: {
        h:50,
        mountY: 0.5, // Center the text both horizontally and vertically
        y: 35, // Center the text vertically
        x: 20, // Center the text horizontally
       alpha:1,
        text: {
          texten: this.bindProp('name'),  
          textColor:0xffffffff,  
          text: this.bindProp('namear'), 
          zindex:99,
          fontFace: 'Regular',
          fontSize: 26,
          textAlign: 'left', // Ensure text is center-aligned          
        },
        shader: {type: Lightning.shaders.Perspective, rx: 3.14},
   
      },
    },
    HighLight: {
      w: 300,
      reuseInstance: false,
      h: 50 ,
      x:5,
      // alpha: 1,
      collision:true,
      rect: true,
     
      shader: {
        type:  Lightning.shaders.RoundedRectangle, radius: [25, 25, 25,25], zindex:1,
         stroke: 2,
         strokeColor: 0xffffffff,
       
        fillColor:0X33000000,
       
      },
      Text: {
        h:50,
        mountY: 0.5, // Center the text both horizontally and vertically
        y:35, // Center the text vertically
        x: 20, // Center the text horizontally
       alpha:1,
        text: {
          textColor:0xffffffff,  
          text: this.bindProp('namear'),
          zindex:99,
          fontFace: 'Regular',
          fontSize: 26,
          textAlign: 'left', // Ensure text is center-aligned           
        },
        
        shader: {
          type:  Lightning.shaders.RoundedRectangle, radius: [0, 0,0,0], stroke: 0,strokeColor:  0x88ffffff,
        },
        shader: {type: Lightning.shaders.Perspective, rx: 3.14},
      },
      
      visible:  this.bindProp("toggled"),
   
    },
 
    HighLight1: {
      w: 300,
      h: 50 ,
      x:5,
 
      // alpha: 1,
      collision:true,
      rect: true,
     
      shader: {
        type:  Lightning.shaders.RoundedRectangle, radius: [25, 25, 25,25], zindex:1,
         stroke: 2,
         strokeColor: 0xFFd2ff00,
       
        fillColor:0xFFd2ff00,
       
      },
      Text: {
      
        h:50, 
        mountY: 0.5, // Center the text both horizontally and vertically
        y:35, // Center the text vertically
        x: 20, // Center the text horizontally
       alpha:1,
        text: {
          textColor:0xff000000,  
          text: this.bindProp('namear'),

          zindex:99,
          fontFace: 'Regular',
          fontSize: 26,
          textAlign: 'left', // Ensure text is center-aligned          
        },
        
        shader: {
          type:  Lightning.shaders.RoundedRectangle, radius: [0, 0,0,0], stroke: 0,strokeColor:  0x88ffffff,
        },
        shader: {type: Lightning.shaders.Perspective, rx: 3.14},
      },
     
      visible: false,
   
    },
 
 
 
  }
}
}
_init() {
 
  this.toggle = false
  const target = document.body
  const event = 'click'
  const handler = () => {
  }
  Registry.addEventListener(target, event, handler)
}
 
_focus() {
  // this.tag('Text').patch({ smooth:{text:{textColor:0xff000000}}})
  this.tag('HighLight1').patch({ visible: true ,})
 
}
 
_unfocus() {
  // this.tag('Text').patch({ smooth:{text:{textColor:0xffffffff}}})
  this.tag('HighLight1').patch({ visible: false })
 
}
 
 
 
set toggled(t){
  this.tog=t
}
_handleEnter(){
 
  this.fireAncestors('$enteredCategory',this.__prop_name);
 this.fireAncestors('$enteredCategoryreset',this.__prop_name);
 
 
 
 
 
 
 
  this.toggle = !this.toggle
  if(this.toggle){
    this.tag('HighLight').patch({ visible: true ,})
  }
  else{
    this.tag('HighLight').patch({ visible: false })
  }
 
}
 
 
 
 
static get width() {
  return 320
}
 
static get height() {
  return 65
}
}
  