import { Lightning, Log } from '@lightningjs/sdk'
 
export default class ProgramGrid extends Lightning.Component {
  static getFonts() {
    return [{ family: 'Regular', url: Utils.asset('fonts/Roboto-Regular.ttf') },
      {family: 'RegularArabic', url: Utils.asset('fonts/FontsFree-Net-URW-DIN-Arabic-1.ttf')}
    ]
  }
  static _template() {
    if(localStorage.getItem('language')=="en"){
    return {
     
      Wrapper1: {
        w: (w) => w,
        h: (h) => h,
        rect: true,
        clipping: true,
        color: 0xff082125,
        shader:{
            type: Lightning.shaders.RoundedRectangle,
            radius: 10,
            stroke: 3,
            strokeColor: 0x33d1d3d4,
          },
        Text: {
          mountY: 1,
          y: (h) => h / 2 +10,
          x: 40,
          w:(w) =>w,
          color: 0xffffffff,
          text: {
            text: this.bindProp('title'),
            fontStyle:"bold",
            fontSize: 30,
            fontFace: 'Regular', // The font family to use

            textAlign: 'left',
            textOverflow:'ellipsis',
            wordWrap:true,
            maxLines:1,
            wordWrapWidth:(w) =>w - 50,
            maxLinesSuffix:'...',
          },
          shader:{
            type: Lightning.shaders.RoundedRectangle,
            radius: 0,
            stroke: 0,
       
          },
         
        },
        Text21:{
          mountY: 1,
          y: (h) => h-20 ,
          x: 40,
          color: 0xffd1d3d4,
          text: {
            text:this.bindProp('time'),
       
            fontSize: 20,
            textAlign: 'center',
          },
          shader:{
            type: Lightning.shaders.RoundedRectangle,
            radius: 0,
            stroke: 0,
       
          },
          visible:this.bindProp('timeVisibility'),
 
        },
        Text22:{
          mountY: 1,
          y: (h) => h-20 ,
          x: this.bindProp('adjust'), 
          color: 0xffd1d3d4,
          text: {
            text:this.bindProp('messagetext'),
       
            fontSize: 20,
            textAlign: 'center',
          },
          shader:{
            type: Lightning.shaders.RoundedRectangle,
            radius: 0,
            stroke: 0,
       
          },
          visible:this.bindProp('timeVisibility'),
 
        },
        Wrapper2: {
          collision:false,
         mount:1,
          x: (w) => w-5,
          y: (h) => h-5,
          w: this.bindProp('boxLen'),
          h: (h) => h/5,
         
          rect: true,
          color:this.bindProp('colorBack'),
        Text3:{
         
          mount: 0.5,
          y: (h) => h / 2+2,
          x: (w) => w / 2,
         
     
          text: {
            text:this.bindProp('status'),
            fontFamily: "Poppins",
            fontStyle:600,
            fontSize: 20,
            textAlign: 'center',
            textColor:this.bindProp('colorLetter'),
       
          },
        },
        shader:{
          type: Lightning.shaders.RoundedRectangle,
          radius: 0,
          stroke: 0,
        },
        visible:this.bindProp('boxVisibility'),
      },
      Wrapper3: {
        collision:true,
        mountY:1,
        x: this.bindProp('adprog'),
                y: (h) => h-35,
        w: (w) =>  w * 0.4,
        h: 5,
        rect: true,
        color:0xff777777,
       
        Text3:{
  
            h:(h)=>h,
            w:this.bindProp('barTimePer'),
             color:0xffffffff,
            colorLeft:0xFFd2ff00,
            colorRight:0xFF02e9fa,
         
            rect:true,
            shader: {type: Lightning.shaders.RadialGradient, radius: 15, pivot: 0.5, innerColor: 0xffffffff},
         
        },
        shader: {type: Lightning.shaders.RadialGradient, radius: 0, pivot: 0, innerColor: 0xff333333},
        visible: this.bindProp('programBarVisibility'),
      },
    },
   
      HighLight: {
        w: (w) => w + 1,
        h: (h) => h + 1,
        alpha: 1,
        rect: true,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 10,  
          stroke: 3.24,
          strokeColor: 0xFFd2ff00,
          fillColor: 0x00000000,
        },
        visible: false,
      },      
    }
  }
  else{
    return {
     
      Wrapper1: {
        w: (w) => w,
        h: (h) => h,
        rect: true,
        clipping: true,
        color: 0xff082125,
        shader:{
            type: Lightning.shaders.RoundedRectangle,
            radius: 10,
            stroke: 3,
            strokeColor: 0x33d1d3d4,
          },
        Text: {
          mountY: 1,
          y: (h) => h / 2 +10,
          x: 40,
          w:(w) =>w,
          color: 0xffffffff,
          text: {
            text: this.bindProp('title'),
            fontStyle:"bold",
            fontSize: 30,
            fontFace: 'RegularArabic', // The font family to use

            textAlign: 'right',
            textOverflow:'ellipsis',
            wordWrap:true,
            maxLines:1,
            wordWrapWidth:(w) =>w - 50,
            maxLinesSuffix:'...',
          },
          
          shader:{
            type: Lightning.shaders.RoundedRectangle,
            radius: 0,
            stroke: 0,
       
          },shader: {type: Lightning.shaders.Perspective, rx: 3.14},
         
         
        },
        Text21:{
          mountY: 1,
          y: (h) => h-20 ,
          x: 40,
          color: 0xffd1d3d4,
          text: {
            text:this.bindProp('time'),
       
            fontSize: 20,
            textAlign: 'center',
          },
          shader:{
            type: Lightning.shaders.RoundedRectangle,
            radius: 0,
            stroke: 0,
       
          },shader: {type: Lightning.shaders.Perspective, rx: 3.14},
          visible:this.bindProp('timeVisibility'),
 
        },
        Text22:{
          mountY: 1,
          y: (h) => h-20 ,
          x: this.bindProp('adjust'),
          color: 0xffd1d3d4,
          text: {
            text:this.bindProp('messagetext'),
       
            fontSize: 20,
            textAlign: 'center',
          },
          shader:{
            type: Lightning.shaders.RoundedRectangle,
            radius: 0,
            stroke: 0,
       
          },shader: {type: Lightning.shaders.Perspective, rx: 3.14},
          visible:this.bindProp('timeVisibility'),
  
        },
        Wrapper2: {
          collision:false,
         mount:1,
          x: (w) => w-5,
          y: (h) => h-5,
          w: this.bindProp('boxLen'),
          h: (h) => h/5,
         
          rect: true,
          color:this.bindProp('colorBack'),
        Text3:{
         
          mount: 0.5,
          y: (h) => h / 2+2,
          x: (w) => w / 2,
         
     
          text: {
            text:this.bindProp('status'),
            fontFamily: "Poppins",
            fontStyle:600,
            
            fontSize: 20,
            textAlign: 'center',
            textColor:this.bindProp('colorLetter'),
      
           
          },
          shader: {type: Lightning.shaders.Perspective, rx: 3.14},
        },
        shader:{
          type: Lightning.shaders.RoundedRectangle,
          radius: 0,
          stroke: 0,
        },
        
        visible:this.bindProp('boxVisibility'),
       
      },
      Wrapper3: {
        collision:true,
        mountY:1, 
        x: this.bindProp('adprog'),
                y: (h) => h-35,
                w: (w) =>  w * 0.4 ,
                h: 5,
        rect: true,
        color:0xff777777,
       
        Text3:{
 
            h:(h)=>h,
            w:this.bindProp('barTimePer'),
             color:0xffffffff,
            colorLeft:0xFFd2ff00,
            colorRight:0xFF02e9fa,
         
            rect:true,
            shader: {type: Lightning.shaders.RadialGradient, radius: 15, pivot: 0.5, innerColor: 0xffffffff},
         
        },
        shader: {type: Lightning.shaders.RadialGradient, radius: 0, pivot: 0, innerColor: 0xff333333},
        visible: this.bindProp('programBarVisibility'),
      },
    },
   
      HighLight: {
        w: (w) => w + 1,
        h: (h) => h + 1,
        alpha: 1,
        rect: true,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 10,  
          stroke: 3.24,
          strokeColor: 0xFFd2ff00,
          fillColor: 0x00000000,
        },
        visible: false,
        
      },      
    }
  }
  }





  _focus() {
    this.tag('HighLight').patch({ visible: true })    
  }
 
  _unfocus() {
    this.tag('HighLight').patch({ visible: false })
  }
 
  static get width() {
    return 212
  }
 
  static get height() {
    return 117
  }
}
       