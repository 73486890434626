import { Lightning } from '@lightningjs/sdk';
import timeGrid from "./TimeGrid";
import { Grid } from '@lightningjs/ui';
// ---------
export default class TimeList extends Lightning.Component {
  // ------------
 
 static _template() {
 
  return {
    Grids: {
      flexItem: { marginLeft: 8 },
      w: (w) => w,
      h: (h) => h,
      type: Grid,
      direction: 'row',
      spacing: 8,
      // color:0x00ffffff,
      color:0x00032226,
      collision: true,
     
     },
  }
}
_init(){
  this.index=0;
}
 
set timearray(v) {
  const timeItems = [];
 
  //  const timeString = v[0].tsStart; //sample time
 
  let endTime = 0;
  let startTime=0;
  let currentTime =0;
  // let progTime;
  // let bar ="Next";
  // let backColor = 0xff0000ff
  // let letterColor= 0xff000000;
  // let timeAmt=0;
  // let timeWidth=0.0;
  // let boxlen=0;
  // let boxvisiblility =false;
  // let timevisibility=false;
   currentTime= printTime();
 
  // const current_time = Math.floor(Date.now() / 1000);
  // const timeGap= current_time-timeString;
  // let timeGapMinutes=timeGap/3600;
  // timeGapMinutes=timeGapMinutes*615;
 
  for (let i = 0; i < v.length; i++) {  
       endTime = v[i+1];
       startTime = v[i];
    //  if (currentTime <= endTime && currentTime >= startTime) {
    //   timeItems.push({ type: timeGrid, title: "Now",boxid:i });
    //   timeItems.push({ type: timeGrid, title: "Next",boxid:i+1});
    //   timeItems.push({ type: timeGrid, title: "Later",boxid:i+2});
    //   i=i+2;
    //   }
      // else{
      timeItems.push({ type: timeGrid, title: startTime, boxid:i});
      // }
  }
  this.tag('Grids').add(timeItems);
  this.tag('Grids').scroll = -632* 8.5;  
 
//   for (let i = 0; i < v.length; i++) {  
//     endTime = v[i+1];
//   startTime = v[i];
 
//   if (currentTime <= endTime && currentTime >= startTime) {
     
// }
//   }
}
 
 
_getFocused() {
  return this.tag('Grids').children[0].children[this.index]
}
 
_handleLeft() {
//  return _getFocused()={
//   return target.tag('Category')
// }
 
  }
 
_handleRight() {
      this.index++
  }
 
static get width() {
  return 1600
}
 
static get height() {
  return 77
}
}
function printTime() {
  const now = new Date();
  let hours = now.getHours().toString().padStart(2, '0');
  let minutes = now.getMinutes().toString().padStart(2, '0');
 
  const timeString = `${hours}:${minutes}`;
 
  return timeString;
}