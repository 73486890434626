import { Lightning, Utils } from '@lightningjs/sdk'
export default class LanguageButton extends Lightning.Component {
   
  static _template() {
    if(localStorage.getItem('language')=="en"){
    return {
        Button:{
            h:60, 
            w: 135,
            collision: true,
            rect:true,
            color:0xff032226, 
            Text: {       
                y: (h) => h / 2-15,
                x: 0, 
                w:(w) =>w,
                color:0xffffffff,
                text: {
                  text: this.bindProp('name'), 
                  fontStyle:"bold",
                  fontSize: 24, 
                  textAlign: 'center',
                  textOverflow:'ellipsis',
                  wordWrap:true,
                  maxLines:1,
                  wordWrapWidth:(w) =>w - 50,
                  maxLinesSuffix:'...',
                },  
                shader:{
                    type: Lightning.shaders.RoundedRectangle,
                    radius: 0,
                    stroke: 0,
                    strokeColor: 0x33d1d3d4,
                  },
        },
        shader:{
            type: Lightning.shaders.RoundedRectangle,
            radius: 10,
            stroke: 3,
            strokeColor: 0x33d1d3d4,
          },
    },
    HighLight:{
        h:60, 
        w: 135,
        key:this.bindProp('key'), 
        collision: true,
        rect:true,
        color:0xff032226, 
        Text: { 
            y: (h) => h / 2-15,
            x: 0, 
            w:(w) =>w,
            color: 0xFFd2ff00,
            text: {
              text: this.bindProp('name'), 
              fontStyle:"bold",
              fontSize: 24, 
              textAlign: 'center',
              textOverflow:'ellipsis',
              wordWrap:true,
              maxLines:1,
              wordWrapWidth:(w) =>w - 50,
              maxLinesSuffix:'...',
            },  
            shader: {
                type: Lightning.shaders.RoundedRectangle,
                radius: 0,  
                stroke: 0,
                strokeColor: 0xFFd2ff00,
              
              },
            
    },
    shader:{
        type: Lightning.shaders.RoundedRectangle,
        radius: 10,
        stroke: 3,
        strokeColor: 0x33d1d3d4,
      },
      
      visible: false,
}
}
    }
    else{
      return {
        Button:{
            h:60, 
            w: 135,
            collision: true,
            rect:true,
            color:0xff032226, 
            Text: {       
                y: (h) => h / 2-15,
                x: 0, 
                w:(w) =>w,
                color:0xffffffff,
                text: {
                  text: this.bindProp('name'), 
                  fontStyle:"bold",
                  fontSize: 24, 
                  textAlign: 'center',
                  textOverflow:'ellipsis',
                  wordWrap:true,
                  maxLines:1,
                  wordWrapWidth:(w) =>w - 50,
                  maxLinesSuffix:'...',
                },  
                shader:{
                    type: Lightning.shaders.RoundedRectangle,
                    radius: 0,
                    stroke: 0,
                    strokeColor: 0x33d1d3d4,
                  },
                  shader: {type: Lightning.shaders.Perspective, rx: 3.14}
        },
        shader:{
            type: Lightning.shaders.RoundedRectangle,
            radius: 10,
            stroke: 3,
            strokeColor: 0x33d1d3d4,
          },
    },
    HighLight:{
        h:60, 
        w: 135,
        key:this.bindProp('key'), 
        collision: true,
        rect:true,
        color:0xff032226, 
        Text: { 
            y: (h) => h / 2-15,
            x: 0, 
            w:(w) =>w,
            color: 0xFFd2ff00,
            text: {
              text: this.bindProp('name'), 
              fontStyle:"bold",
              fontSize: 24, 
              textAlign: 'center',
              textOverflow:'ellipsis',
              wordWrap:true,
              maxLines:1,
              wordWrapWidth:(w) =>w - 50,
              maxLinesSuffix:'...',
            },  
            shader: {
                type: Lightning.shaders.RoundedRectangle,
                radius: 0,  
                stroke: 0,
                strokeColor: 0xFFd2ff00,
              
              },
              shader: {type: Lightning.shaders.Perspective, rx: 3.14},
            
    },
    shader:{
        type: Lightning.shaders.RoundedRectangle,
        radius: 10,
        stroke: 3,
        strokeColor: 0x33d1d3d4,
      },
      
      visible: false,
}
}
    }
}
_focus() {
    this.tag('HighLight').patch({ visible: true })    
  }
 
  _unfocus() {
    this.tag('HighLight').patch({ visible: false })
  }
  _handleEnter(){
    if(this.__prop_key!=localStorage.getItem('language')){
        localStorage.setItem('language',this.__prop_key);
 
    window.location.reload(true); 

  }

else{
    this.fireAncestors('$LanguageSelected');
}
  } 
}