import { Lightning, Utils } from '@lightningjs/sdk'
// import MediaPlayer from './MediaPlayer'
// import MediaPlayer from './MediaPlayerShaka'
// import MediaPlayer from './MediaPlayerBitmovin'
export default class DetailScreen extends Lightning.Component {
  static getFonts() {
    return [{ family: 'Regular', url: Utils.asset('fonts/Roboto-Regular.ttf') },
      {family: 'RegularArabic', url: Utils.asset('fonts/FontsFree-Net-URW-DIN-Arabic-1.ttf')}
    ]
  }
  static _template() {
    if(localStorage.getItem('language')=="en"){
    return {
      Background: {
        // w: 1920,
        // h: 1080,
        rect: true,
        x: 135,
        y: 0,
        w: 1920, // Full screen width
        h: 400, // Quarter of the screen height
        color: 0x000d2b3d,//0xff0000ff, // Example color, change as needed
        // BackgroundImg: {
        //     x: (w)=>w ,
        //     mountX:1,
        //     y: 0,
        //     clipping:true,
        //     w: 1000, // Full screen width
        //     h: 510, // Quarter of the screen height
        //    src: this.bindProp('backimg'),
        //     shader: {type: Lightning.shaders.FadeOut, fade: [0, 0, 0, 500]}
        //   },
        Player: {
          x: (w)=>w ,
          mountX:1,
          y: 0,
          // zIndex: 100,
          clipping:true,
          w: 1000, // Full screen width
          h: 510, // Quarter of the screen height
          // bgImg: this.bindProp('backimg'),     //no access 
          // type:MediaPlayer,
          shader: {type: Lightning.shaders.FadeOut, left:500}//fade: [0, 0, 0, 500]
        }, 
          BackButton:{
            collision:true,
            x: 50,
            y: 35,
            w: 60,
            h: 60,  
            src: Utils.asset('images/backwhite.png'),  
            shader:{
              type: Lightning.shaders.RoundedRectangle, radius: 10,
              },
          },
          Backdetail: {
            x: 130,
            y: 50, 
            text: {
             
              text: 'Back',
              fontFamily: "Poppins",
              fontSize: 24,
              fontStyle:600,
              textColor: 0xffffffff,
            },
          },
          Icon: {
            x: 50,
            y: 115,
            w: 180,
            h: 80,
            src:this.bindProp('iconimg'),
           
            shader:{
            type: Lightning.shaders.RoundedRectangle, radius: 10,
            },
          },
          Title: {
            x: 50,
            y: 210,
            text: {
             
              text: this.bindProp('movieName'),
              fontFamily: "Poppins",
              fontSize: 48,
              fontStyle:700,
              textColor: 0xffffffff,
            },
          },
          Wrapper2: {
         
             x: 50,
             y: 280,
             w: this.bindProp('boxLen'),
             h:  40,
             collision:true,
             rect: true,
             color:this.bindProp('backColorD'),
             shader:{
              type: Lightning.shaders.RoundedRectangle, radius: 3,
              },
           Text3:{
             
             mount: 0.5,
             y: (h) => 3*h / 5,
             x: (w) => w / 2,
             
         
             text: {
               text: this.bindProp('word'),
               fontSize: 24,
               fontStyle:600,
               textAlign: 'center',
               textColor:this.bindProp('letterColorD'),
             },
           },
         },
          Time1: {
            x: 210,
            y: 285,
           
            text: {
              text: this.bindProp('progTimeD'),
              fontFace: 'Regular',
              fontFamily: "Averta Std PE",
              fontStyle:"bold",
              fontSize: 26,
              textColor: 0xffffffff,
            },
          },
          Time2: {
            x:  this.bindProp('adjustlength'),
            y: 285,
           
            text: {
              text: this.bindProp('messageText'),
              fontFace: 'Regular',
              fontFamily: "Averta Std PE",
              fontStyle:"bold",
              fontSize: 26,
              textColor: 0xffffffff, 
            },
          },
          Description: {
            x: 50,
            y: 330,
            w: 750,
            h:60,
            text: {
              //text: "DOCUMENTARY  •  BIOGRAPHY  •  MUSIC",
              text: this.bindProp('movieDesp'),
               fontFamily: "Averta Std PE",
              fontFace: 'Regular',
              fontSize: 24,
              textColor: 0xFFd2ff00,
              lineHeight: 30,
              lineSpacing: 5,
              maxLines:2,
              maxLinesSuffix:"...",
              wordWrap:true,
            },
          },
      },
    }
  }
  else{
    return {
      Background: {
        // w: 1920,
        // h: 1080,
        rect: true,
        x: 135,
        y: 0,
        w: 1920, // Full screen width
        h: 400, // Quarter of the screen height
        color: 0x000d2b3d,//0xff0000ff, // Example color, change as needed
        // BackgroundImg: {
        //     x: (w)=>w ,
        //     mountX:1,
        //     y: 0,
        //     clipping:true,
        //     w: 1000, // Full screen width
        //     h: 510, // Quarter of the screen height
        //    src: this.bindProp('backimg'),
        //     shader: {type: Lightning.shaders.FadeOut, fade: [0, 0, 0, 500]}
        //   },
        Player: {
          x: (w)=>w ,
          mountX:1,
          y: 0,
          // zIndex: 100,
          clipping:true,
          w: 1000, // Full screen width
          h: 510, // Quarter of the screen height
          // bgImg: this.bindProp('backimg'),     //no access 
          // type:MediaPlayer,
          shader: {type: Lightning.shaders.FadeOut, left:500}//fade: [0, 0, 0, 500]
          
        }, 
          BackButton:{
            collision:true,
            x: 50,
            y: 35,
            w: 60,
            h: 60,  
            src: Utils.asset('images/backwhite.png'),  
            shader:{
              type: Lightning.shaders.RoundedRectangle, radius: 10,
              },
              shader: {type: Lightning.shaders.Perspective, rx: 3.14} 
          },
          Backdetail: {
            x: 130,
            y: 50, 
          
            text: {
             
              text: 'الرجوع',
              fontFamily: "Poppins",
              fontSize: 24,
              fontStyle:600,
              textColor: 0xffffffff,
             
          },
          shader: {type: Lightning.shaders.Perspective, rx: 3.14}  
          
        },
        
          Icon: {
            x: 50,
            y: 115,
            w: 180,
            h: 80,
            src:this.bindProp('iconimg'),
           
            shader:{
            type: Lightning.shaders.RoundedRectangle, radius: 10,
            },
            shader: {type: Lightning.shaders.Perspective, rx: 3.14}
          },
          Title: {
            x: 50,
            y: 210,
            text: {
             
              text: this.bindProp('movieName'),
              fontFamily: "Poppins",
              fontSize: 48,
              fontStyle:700,
              textColor: 0xffffffff,
              textAlign:"right",
            },
            shader: {type: Lightning.shaders.Perspective, rx: 3.14},
          },
          Wrapper2: {
         
             x: 50,
             y: 280,
             w: this.bindProp('boxLen'),
             h:  40,
             collision:true,
             rect: true,
             color:this.bindProp('backColorD'),
             shader:{
              type: Lightning.shaders.RoundedRectangle, radius: 3,
              },
           Text3:{
             
             mount: 0.5,
             y: (h) => 3*h / 5,
             x: (w) => w / 2,
             
         
             text: {
               text: this.bindProp('word'),
               fontSize: 24,
               fontStyle:600,
               textAlign: 'center',
               textColor:this.bindProp('letterColorD'),
             },
             shader: {type: Lightning.shaders.Perspective, rx: 3.14},
           },
         },
         Time1: {
          x: 210,
          y: 285,
         
          text: {
            text: this.bindProp('progTimeD'),
            fontFace: 'Regular',
            fontFamily: "Averta Std PE",
            fontStyle:"bold",
            fontSize: 26,
            textColor: 0xffffffff,
          },
          shader: {type: Lightning.shaders.Perspective, rx: 3.14},
        },
        Time2: {
          x:  this.bindProp('adjustlength'),
          y: 285,
         
          text: {
            text: this.bindProp('messageText'),//adjustlength
            fontFace: 'Regular',
            fontFamily: "Averta Std PE",
            fontStyle:"bold",
            fontSize: 26,
            textColor: 0xffffffff, 
          },
          shader: {type: Lightning.shaders.Perspective, rx: 3.14}, 
        },
          Description: {
            x: 50,
            y: 330,
            w: 750,
            h:60,
            text: {
              //text: "DOCUMENTARY  •  BIOGRAPHY  •  MUSIC",
              text: this.bindProp('movieDesp'),
               fontFamily: "Averta Std PE",
              fontFace: 'Regular',
              fontSize: 24,
              textColor: 0xFFd2ff00,
              textAlign:'right',
              lineHeight: 30,
              lineSpacing: 5,
              maxLines:2,
              maxLinesSuffix:"...",
              wordWrap:true,
            },
            shader: {type: Lightning.shaders.Perspective, rx: 3.14},
          },
      },
    }
  }
  }
 
  set channelObj(cObj){
    this.iconimg=cObj.url
 
  }
  set movieType(tObj){
    this.mType=cObj
 
  }
 
  set movieImage(iObj){
    this.backimg=iObj
 
  }

 
  set movieObj(mObjtype){
    let mObj=mObjtype.movie
    this.categorytype=mObjtype.type  
    this.lang=localStorage.getItem('language')
    let endTime=0
    let startTime=0
    this.movieName=mObj.title
    let i=0;
    let boxlen=0;
    let backColor = 0xff0000ff
    let letterColor= 0xff000000;
    let bar ="Next"; 
    endTime = convertTimestampToRealTime(mObj.tsEnd);
    startTime = convertTimestampToRealTime(mObj.tsStart);
    let message=""
    let genreList=[]
    let genredata=""
    this.movieTiming = startTime+" - "+endTime;
  
    let currentTime= printTime();
    let timeLeft=0;
    let totalTime=0;
    let intEndTime=0
    let intStartTime=0
    let intCurrentTime=0
    if(this.categorytype=="playlist"){ 
      genreList=mObj.genres
      // mObj.genres.forEach(genre => {
      //   genredata.concat(genre) 
      //   genredata.concat(" | ")
      // })
      this.movieDesp=mObj.genres.join(" | "); 

      }
      else{
        this.movieDesp=mObj.description
      }
    let progTime=""
    if(this.lang=="en"){
      progTime= startTime+" - "+endTime;
   }
   else{
     progTime= endTime+" - "+startTime;
   }
    if (currentTime <= endTime && currentTime >= startTime) {
     
      this.index=i
 
      intEndTime=convertTimeToMinutes(endTime);
      intStartTime=convertTimeToMinutes(startTime);
      intCurrentTime=convertTimeToMinutes(currentTime);
      totalTime=intEndTime-intStartTime;
      //timeLeft=intEndTime-intCurrentTime;
      timeLeft=calculateTimeDifference(currentTime, endTime)
     
      if(this.lang=="en"){
        message="mins left"
      }
      else{
        message="الدقائق المتبقية "  
      }
 
        progTime=timeLeft//currentTime+" - "+endTime   //differenceInMinutes+" mins left";

     
     if(this.lang=="en"){
      if(this.categorytype=="linear"){
        bar = "LIVE";
      }
     else{
      bar = "Watch Now";  
    }
        }
   else{
    if(this.categorytype=="linear"){
      bar = "بث مباشر";
   } 
   else{
    bar = "شاهد الآن";  
  }
  }        boxlen=bar.length;
        backColor=0xffff0000;
        letterColor= 0xffffffff;
       
      } else if(currentTime > endTime) {
       
        if(this.lang=="en"){
          bar = "Catch up";
        }
       else{
        bar = "تسجيل";
      }
           message=""
        boxlen=bar.length;
        backColor= 0xffffffff;
        letterColor= 0xff000000;
     
      }
      else{
     
        if(this.lang=="en"){
          bar = "Next";
        }
       else{
        bar = "التالي";
      }
           message=""
        boxlen=bar.length;
        backColor= 0xffffffff;
        letterColor= 0xff000000;
      }
    this.word=bar
    this.backColorD=backColor
    this.letterColorD=letterColor
    if(this.lang=="en"){
    boxlen=boxlen*16;
    }
    else{
      boxlen=boxlen*13;
    }
    this.boxLen=boxlen;
    this.progTimeD=progTime
    this.messageText=message
    this.adjustlength=progTime.length*15+220   

 
  }
  _init() {
    this.adjustlength=0
    this.movieName=""
    this.movieTiming=""
    this.movieDesp=""
    this.backimg=""
    this.iconimg=""
    this.word=""
    this.backColorD=""
    this.letterColorD=""
    this.boxLen=0
    this.progTimeD=""
    this.messageText=""
  }
}
 
function printTime() {
  const now = new Date();
  let hours = now.getHours().toString().padStart(2, '0');
  let minutes = now.getMinutes().toString().padStart(2, '0');
 
  const timeString = `${hours}:${minutes}`;
 
  return timeString;
}
function convertTimestampToRealTime(timestamp) {
  const date = new Date(timestamp * 1000);
 
  const hours = date.getHours();
  const minutes = date.getMinutes();
 
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
 
  return formattedTime;
}
 
 
// string to minute convertion
function convertTimeToMinutes(timeString) {
  // Check if the time string is in the correct format (HH:MM)
  const timeRegex = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/;
  if (!timeRegex.test(timeString)) {
    throw new Error("Invalid time format. Please use HH:MM format.");
  }
 
  // Split the time string into hours and minutes
  const [hours, minutes] = timeString.split(":");
 
  // Convert hours and minutes to integers
  const hoursInt = parseInt(hours);
  const minutesInt = parseInt(minutes);
 
  // Validate hours and minutes
  if (hoursInt < 0 || hoursInt > 23 || minutesInt < 0 || minutesInt > 59) {
    throw new Error("Invalid time values. Hours must be between 0 and 23, minutes must be between 0 and 59.");
  }
 
  // Calculate total minutes since midnight
  return hoursInt * 60 + minutesInt;
}
function calculateTimeDifference(time1, time2) {
  const [hours1, minutes1] = time1.split(":").map(Number);
  const [hours2, minutes2] = time2.split(":").map(Number);

  const totalMinutes1 = hours1 * 60 + minutes1;
  const totalMinutes2 = hours2 * 60 + minutes2;

  const timeDifference = totalMinutes2 - totalMinutes1;

  const hoursDifference = Math.floor(timeDifference / 60);
  const minutesDifference = timeDifference % 60;
  let time=minutesDifference+hoursDifference*60

  return `${time}`;
}