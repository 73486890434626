import { Lightning } from '@lightningjs/sdk'
import { Grid } from '@lightningjs/ui'
import ProgramGrid1 from './ProgramGrid1'
export default class ProgramList5 extends Lightning.Component {
  static _template() {
    return {
      Grids: {
        w: (w) => w,
        h: (h) => h, 
        spacing: 8, 
        direction: 'row',
        type: Grid,   
        signals: { onIndexChanged: 'staticWidthGrids'},  
        scroll:0,
        // chatype:this.bindProp("channeledtype")
      }, 
   
   
    }
  }
  set channeltype(t){
    this.type= t

  }
  set number(id){
    this.channellistid=id
  }
 
set channelImg(img){
  this.channelImage= img.url
this.imageChannel=this.channelImage.url
}
set dvrr(w) {
  let v=w[1];
  this.categorytype=w[0];
 this.liveprogindex=0
    const programItems = [];
    let endTime = 0;
    let startTime=0;
    let progTime;
    let bar ="Next";
    let backColor = 0xff0000ff
    let letterColor= 0xff000000;
    let timeAmt=0;
    let msgvisiblility =false;
    let timeWidth=0.0;
    let boxlen=0;
    let boxvisiblility =true;
    let timevisibility=true;
    let progressbarvisibility= true;
    let currentTime= printTime();
    let timeLeft=0;
    let totalTime=0;
    let barTime=0;
    let scrollWidth=0;
    let intEndTime=0
    let intStartTime=0
    let intCurrentTime=0
    let tempStart=0;
    this.lang=localStorage.getItem('language')

    let distance=0;
    let message=""

    
  //  let hilo=imageopen();
  
    // let channelImg=this.channelImage; 
   // const current_time = Math.floor(Date.now() / 1000);
    let id1=0;
    for (let i = 0; i < v.length; i++) {  
         endTime = convertTimestampToRealTime(v[i].tsEnd);
         startTime = convertTimestampToRealTime(v[i].tsStart);
         timeAmt= v[i].tsEnd-v[i].tsStart
         
   
 
     

let a=15264/24; //width of timegrid=628, spacing=8 | total width = (628+8)*24
      timeWidth=timeAmt/3600;
      timeWidth=timeWidth*a;  
      timeWidth=timeWidth-8;  
      if(this.lang=="en"){
        progTime= startTime+" - "+endTime;
     }
     else{
       progTime= endTime+" - "+startTime;
     }
 distance=distance+timeWidth+8
 
      if (currentTime < endTime && currentTime >= startTime) {  
      tempStart=v[i].tsStart
      this.index=i
   
 
      intEndTime=convertTimeToMinutes(endTime);
      intStartTime=convertTimeToMinutes(startTime);
      intCurrentTime=convertTimeToMinutes(currentTime);
      timeLeft=calculateTimeDifference(currentTime, endTime) 

      totalTime=intEndTime-intStartTime;
      // timeLeft=intEndTime-intCurrentTime;
      barTime=((totalTime - timeLeft) / totalTime) *200,
   
      this.liveprogindex=i
      // distance=615/60;
      // distance=distance*(intStartTime-300); 
      // distance=distance+(this.liveprogindex*16) 
      distance=distance-timeWidth-8
      this.distancesprog=distance; 





      msgvisiblility=true 
      
        progTime=timeLeft//currentTime+" - "+endTime   //differenceInMinutes+" mins left";
      
     //currentTime+" - "+endTime   //differenceInMinutes+" mins left"; 
        progressbarvisibility=true;
 
        if(this.lang=="en"){
          message="mins left"
        }
        else{
          message="الدقائق المتبقية "  
        }
      if(this.lang=="en"){
        bar = "LIVE";
        if(this.categorytype=="linear"){
          bar = "LIVE";
        }
       else{
        bar = "Watch Now";  
      }
      }
     else{
      if(this.categorytype=="linear"){
        bar = "بث مباشر";
      }
     else{
      bar = "شاهد الآن";  
    }
      }
        boxlen=bar.length;
        backColor=0xffff0000;
        letterColor= 0xffffffff;
  
        id1=i+1;
        
      } else if(currentTime > endTime) {
        barTime=0
        progressbarvisibility=false;
        if(this.lang=="en"){
          bar = "Catch up";
        }
       else{
        bar = "تسجيل";
      }
        message=""
        boxlen=bar.length;
        backColor= 0xffffffff;
        letterColor= 0xff000000;
      }
      else{
   
        progressbarvisibility=false;
        if(this.lang=="en"){
          bar = "Next";
        }
       else{
        bar = "التالي";
      }
           barTime=0;
        message=""
        boxlen=bar.length;
        backColor= 0xffffffff;
        letterColor= 0xff000000; 
      }
      let adjustlength=timeLeft.length*10+45 
      let adjustprog= timeLeft.length*10+160  
      boxlen=boxlen*12;
    
      programItems.push({ type: ProgramGrid1, title: v[i].title, w: 500 , time:progTime, status:bar, colorBack: backColor, colorLetter: letterColor, boxLen:boxlen, boxVisibility:boxvisiblility,timeVisibility :timevisibility, programBarVisibility:progressbarvisibility,barTimePer:barTime,v: v[i],messagetext:message,msgVisiblility:msgvisiblility,adjust:adjustlength,adprog:adjustprog});    
       
      }
     
    this.tag('Grids').add(programItems);
    
    // this.tag('Grids').tag('Grid')._scrollTransition.start(-400); 
    // this.fireAncestors('$moveList4', this.distancesprog ,this.liveprogindex)
 
    // this.tag('Grids').scroll=this.distancesprog 
    // this.tag('Grids')._scrollTransition=(-this.distancesprog );
  //  this.moveList4(this.distancesprog); 
    // this.tag('Grids').items[this.liveprogindex]._getFocused() 
  //   this.tag('List').scroll = (itemWrapper, indexData) => {
  //     //calculation
  //     return 100 -  this.distancesprog
  // }
    scrollWidth= (tempStart-v[0].tsStart)/3600; 
 
  //  function moveList4(scrollValue) { 
  //     this.tag('Programs').items.map((d) => {
  //         if (d.isAlive) {
  //             d.tag('Grids')._scrollTransition.start(-scrollValue);
  //         }
  //     });
  //     this.positionX = scrollValue;
  //     this.fireAncestors('$scrollToTimeWrapper', scrollValue); 
  
  //   }


     
}
  getTimeDiffInMin(startDate, endDate) {
    const d2 = new Date(endDate)
    const d1 = new Date(startDate)
    const diffMs = d2.getTime() - d1.getTime()
    return Math.floor(diffMs / 1000 / 60)
  }
 
  

  // _handleUp(){
  //   this.gridIndexChanged
  // }
 
  // _handleDown(){
  //   this.gridIndexChanged
  // }

  staticWidthGrids(obj){  
    this.what=this.tag('Grids').tag('Wrapper').parent.items[obj.index].v
    this.fireAncestors('$moveList8',this.channellistid,obj.index, this.liveprogindex) 
  }
// gridIndexChanged(obj) {
//   this.what2=this.tag('Grids').tag('Wrapper').parent.items[this.liveprogindex].v
//   this.dis=this.tag('Grids').tag('Wrapper').children[this.liveprogindex].x 
//   this.fireAncestors('$forDetailScreenObj2', this.what2,  this.imageChannel);
//   const scrollValue = this.tag('Grids').tag('Wrapper').children[obj.index].x

//   // this.fireAncestors('$moveList', scrollValue)
//   // this.fireAncestors('$moveListmain', scrollValue)
//   // const indexNum = obj.index;
//   this.fireAncestors('$moveList8', scrollValue,this.channellistid,obj.index, this.liveprogindex) 
//   this.fireAncestors('$gridIndexChanged2',obj.index);  
//  this.what=this.tag('Grids').tag('Wrapper').parent.items[obj.index].v
//  this.tag('Grids').scroll= 0; 
//   // this.fireAncestors('$forDetailScreenObj', this.tag('Grids').tag('Wrapper').parent.items[obj.index].v, this.imageChannel);
//  }

_init(){

// this.channellistid=
this.what=this.tag('Grids').tag('Wrapper').parent.items[this.liveprogindex].v 
this.what3=this.tag('Grids').tag('Wrapper').parent.items[this.liveprogindex].v 
this.fireAncestors('$forDetailScreenObj3', this.what3, this.imageChannel,this.categorytype);

this.fireAncestors('$gridIndexChanged2', this.liveprogindex);  
// let i=0
// let n=this.liveprogindex
// // this.tag('Grids').setIndex(this.liveprogindex,"immediate") 
// for(i=0;i<n;i++){
//   this.tag('Grids').next()
// }
this.fireAncestors('$gridIndexChanged3', this.liveprogindex); 
this.tag('Grids').setIndex(this.liveprogindex,"immediate")  
this.tag('Grids').scroll={after:0}                    
// this.tag('Grids').scroll= 0;        

}

 _handleEnter(){
  this.fireAncestors('$forDetailScreenObj', this.what, this.imageChannel,this.categorytype);
 }

_focus() {
  if (this.tag('Grids').tag('Wrapper').children.length === 0) {
      return; // Exit early if the array is empty
  }
  // const preScrollValue = this.fireAncestors('$getPreScrollValue');
  // const closest = this.tag('Grids')
  //   .tag('Wrapper')
  //   .children.reduce((prev, curr) => {
  //     return Math.abs(curr.x - preScrollValue) < Math.abs(prev.x - preScrollValue) ? curr : prev
  //   });

  // this.tag('Grids').setIndex(closest.componentIndex);
}
_handleLeft() {
if(localStorage.getItem('language')=="en")
  {
this.fireAncestors('_setState', 'CategoryFocus'); 
  }
}
_handleRight() {
  if(localStorage.getItem('language')!="en")
    {
  this.fireAncestors('_setState', 'CategoryFocus'); 
    }
  }

_getFocused() {
  return this.tag('Grids')
  }
  
  

_handleEnter(){
  this.fireAncestors('$forDetailScreenObj', this.what, this.imageChannel,this.categorytype);
 }

static get width() {
  return 1600
}

static get height() {
  return 117
}
}
function convertTimestampToRealTime(timestamp) {
const date = new Date(timestamp * 1000);

const hours = date.getHours();
const minutes = date.getMinutes();

const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

return formattedTime;
}

function printTime() {
const now = new Date();
let hours = now.getHours().toString().padStart(2, '0');
let minutes = now.getMinutes().toString().padStart(2, '0');

const timeString = `${hours}:${minutes}`;

return timeString;
}


setInterval(printTime, 1000);



// string to minute convertion
function convertTimeToMinutes(timeString) {
// Check if the time string is in the correct format (HH:MM)
const timeRegex = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/;
if (!timeRegex.test(timeString)) {
  throw new Error("Invalid time format. Please use HH:MM format.");
}

// Split the time string into hours and minutes
const [hours, minutes] = timeString.split(":");

// Convert hours and minutes to integers
const hoursInt = parseInt(hours);
const minutesInt = parseInt(minutes);

// Validate hours and minutes
if (hoursInt < 0 || hoursInt > 23 || minutesInt < 0 || minutesInt > 59) {
  throw new Error("Invalid time values. Hours must be between 0 and 23, minutes must be between 0 and 59.");
}

// Calculate total minutes since midnight
return hoursInt * 60 + minutesInt;
}

function calculateTimeDifference(time1, time2) {
  const [hours1, minutes1] = time1.split(":").map(Number);
  const [hours2, minutes2] = time2.split(":").map(Number);

  const totalMinutes1 = hours1 * 60 + minutes1;
  const totalMinutes2 = hours2 * 60 + minutes2;

  const timeDifference = totalMinutes2 - totalMinutes1;

  const hoursDifference = Math.floor(timeDifference / 60);
  const minutesDifference = timeDifference % 60;
  let time=minutesDifference+hoursDifference*60

  return `${time}`;
}
