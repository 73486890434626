import { Lightning, Utils } from '@lightningjs/sdk';

export default class MenuScreen1 extends Lightning.Component {
  static getFonts() {
    return [{ family: 'Regular', url: Utils.asset('fonts/Roboto-Regular.ttf') }];
  }

  static _template() {
    if(localStorage.getItem('language')=="en"){
    return {
      Wrapper1: {
        w: (w) => w,
        h: (h) => h,
        rect: true,
        color: 0xff032226,
        shader: { type: Lightning.shaders.FadeOut, fade: [0, 450, 0, 0] },
        clipping: true,
        Text: {
          x: 40,
          y: 40,
          shader: { type: Lightning.shaders.FadeOut, fade: [0, 0, 0, 0] },
          text: this.bindProp('name'),
          fontFace: 'Regular',
          fontSize: 12, // Bind the fontSize property
          textColor: 0xffffffff,
          textAlign: 'center',
        },
      },
      // ... other components
    };
  }
    else{
    return {
      Wrapper1: {
        w: (w) => w,
        h: (h) => h,
        rect: true,
        color: 0xff032226,
        shader: { type: Lightning.shaders.FadeOut, fade: [0, 450, 0, 0] },
        clipping: true,
        Text: {
          x: 40,
          y: 40,
          shader: { type: Lightning.shaders.FadeOut, fade: [0, 0, 0, 0] },
          text: this.bindProp('name'),
          fontFace: 'Regular',
          fontSize: 12, // Bind the fontSize property
          textColor: 0xffffffff,
          textAlign: 'center',
        },
        shader: {type: Lightning.shaders.Perspective, rx: 3.14}, 

      },
      // ... other components
    };
  }
  }

  // ... other methods

  static get width() {
    return 800;
  }

  static get height() {
    return 120;
  }
}