import { Lightning, Utils } from '@lightningjs/sdk'
export default class ChannelList extends Lightning.Component {
  static _template() {
    if(localStorage.getItem('language')=="en"){
    return {
      Wrapper1: {  
        w: (w) => w,
        h: (h) => h,
        rect: true,
        color: 0xff0D2B3D, // Dark teal blue color,0xffff2323,
        shader:{
          type: Lightning.shaders.RoundedRectangle,
          radius: 12,
          stroke: 3,
          strokeColor: 0x1a444444,  
        },
        clipping:true,
        // shader: {type: Lightning.shaders.RoundedRectangle, radius: 10},
 
        UsingTexture:{
          w: (w) => w,
          h: (h) => h,
 
          // shader: {
          //   type: Lightning.shaders.RoundedRectangle,
          //   radius: 10,
          //   stroke: 3.24,
          //   strokeColor:0xff032228,
          // },
 
          texture: {
            type: lng.textures.ImageTexture,
            src: this.bindProp('name'),
          },
        },
      },
    }
  }
  else{
    return {
      Wrapper1: {  
        w: (w) => w,
        h: (h) => h,
        rect: true, 
        color: 0xff0D2B3D, // Dark teal blue color,0xffff2323,
        shader:{
          type: Lightning.shaders.RoundedRectangle,
          radius: 12,
          stroke: 3,
          strokeColor: 0x1a444444,  
        },
        clipping:true,
        // shader: {type: Lightning.shaders.RoundedRectangle, radius: 10},
 
        UsingTexture:{
          w: (w) => w,
          h: (h) => h,
 
          texture: {
            type: lng.textures.ImageTexture,
            src: this.bindProp('name'),
          },
          shader: {type: Lightning.shaders.Perspective, rx: 3.14}
        },
      },
    }
  }
  }
 
  static get width() {
    return 220
  }
 
  static get height() {
    return 117
  }
}
 