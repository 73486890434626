import { Lightning, Utils } from '@lightningjs/sdk'

import { List } from '@lightningjs/ui'
import LanguageButton from './LanguageButton'
export default class MenuList extends Lightning.Component {

  static _template() {
    if(localStorage.getItem('language')=="en"){
    return {
      Wrapper1: {   
        collision: true,
        w: (w) => w,
        h: (h) => h,
        rect: true,
        type: Lightning.components.Image,
        color:0xff032226,
        label:this.bindProp('name2'), 
        mountX: 0,
        // mountY: ,
        UsingTexture:{
          x: 45,
          y: 50,
          w: 35,
          h: 35,
          texture: {
            type: lng.textures.ImageTexture,
            src: this.bindProp('name'),
          },
        }, 
      },
      Options:{ 
        x: 135, 
        y: 0,
 
        collision: true,
        w: (w) => w,
     
        direction: 'column',
        type: List,
        zIndex:5,
        visible: false, 
      },
      // Options:{ 
      //   x: 135, 
      //   y: 0,
      //   h: 120, 
      //   collision: true,
      //   w: (w) => w,
      //   rect:true,
      //   color:0xff0000ff, 
      //   direction: 'column',
      //   type: List,
      //   zIndex:5,
      //   scroll: {
      //     after: 10,  
      //   },
      //   visible: true,
      // },
      HighLight: {
          w: (w) => w,
        h: (h) => h,
        rect: true,
        type: Lightning.components.Image,
        // color:0xff032226,
        color: 0x88d2ff00,
        shader: { type: Lightning.shaders.FadeOut, fade: [0, 300  , 0, 0] },
        clipping:true,
        mountX: 0,
        UsingTexture:{
          shader: { type: Lightning.shaders.FadeOut, fade: [0, 0, 0, 0] },
          x: 45,
          y: 50,
          w: 35,
          h: 35,
          texture: {
            shader: { type: Lightning.shaders.FadeOut, fade: [0, 0, 0, 0] },
            type: lng.textures.ImageTexture,
            src: this.bindProp('name1'),
          },
          
        },
        visible: false,
      
      },
    }
  }
  else{
    return {
      Wrapper1: {   
        collision: true,
        w: (w) => w,
        h: (h) => h,
        rect: true,
        type: Lightning.components.Image,
        color:0xff032226,
        label:this.bindProp('name2'), 
        mountX: 0,
        // mountY: ,
        UsingTexture:{
          x: 45,
          y: 50,
          w: 35,
          h: 35,
          texture: {
            type: lng.textures.ImageTexture,
            src: this.bindProp('name'),
          },
          shader: {type: Lightning.shaders.Perspective, rx: 3.14}
        }, 
      },
      Options:{ 
        x: 135, 
        y: 0,
 
        collision: true,
        w: (w) => w,
     
        direction: 'column',
        type: List,
        zIndex:5,
        visible: false, 
      },
      // Options:{ 
      //   x: 135, 
      //   y: 0,
      //   h: 120, 
      //   collision: true,
      //   w: (w) => w,
      //   rect:true,
      //   color:0xff0000ff, 
      //   direction: 'column',
      //   type: List,
      //   zIndex:5,
      //   scroll: {
      //     after: 10,  
      //   },
      //   visible: true,
      // },
      HighLight: {
          w: (w) => w,
        h: (h) => h,
        rect: true,
        type: Lightning.components.Image,
        // color:0xff032226,
        color: 0x88d2ff00,
        shader: { type: Lightning.shaders.FadeOut, fade: [0, 300  , 0, 0] },
        clipping:true,
        mountX: 0,
        UsingTexture:{
          shader: { type: Lightning.shaders.FadeOut, fade: [0, 0, 0, 0] },
          x: 45,
          y: 50,
          w: 35,
          h: 35,
          texture: {
            shader: { type: Lightning.shaders.FadeOut, fade: [0, 0, 0, 0] },
            type: lng.textures.ImageTexture,
            src: this.bindProp('name1'),
        
          },
          shader: {type: Lightning.shaders.Perspective, rx: 3.14}
        },
        visible: false,
      
      },
    }
  }
  }

  _focus() {
    this.tag('HighLight').patch({ visible: true ,})
   
  }
  
  _unfocus() {
    this.tag('HighLight').patch({ visible: false })
  
  }

  _init(){
    this._setState('MenuListFocus'); 
    this.index=0 
    let OptionList=[]  
    if(localStorage.getItem('language')=="en"){
     OptionList=[{Label:"English",Key:"en"},{Label:"عربي",Key:"ar"}]  
    }else{
      OptionList=[{Label:"عربي",Key:"ar"},{Label:"English",Key:"en"}] 
    }
    let OptionButtons=[]
    let i=0  
    OptionList.forEach(LB => {
      OptionButtons.push({ type: LanguageButton, name: LB.Label,key:LB.Key,h:60,w:135});   
      i++; 
    });
    this.tag('Options').add(OptionButtons);
  }
  static _states() {
    return [
      
        class MenuListFocus extends this {
          
            _getFocused() {
                return this
            }

            _handleBack(){
              this.tag('Options').patch({ visible: false }) 
            
            } 
  _handleEnter(){
    if(this.__prop_name2=="Settings"){
    this.tag('Options').patch({ visible: true }) 
      this._setState('ButtonFocus'); 
    }  
  }
       

        },
        class ButtonFocus extends this {
          _getFocused() {
            return this.tag('Options').items[this.index].collectionWrapper; 
            }
            _handleBack(){ 
              this._setState('MenuListFocus'); 
            } 
            _handleUp() {
              if(this.index === 0) {
                  this.index = 1;
              }
              else {
                  this.index -= 1;
              }
          }
      
          _handleDown() {
              if(this.index === 1 ) {
                  this.index = 0;
              }
              else {
                  this.index += 1;
              }
            }
            _handleLeft() {
              this.tag('Options').patch({ visible: false }) 
              this._setState('MenuListFocus'); 
          }
          _handleRight() {
            this._setState('ButtonFocus'); 
        }
    
        }, 
    ];
  }
  $LanguageSelected(){
    this.tag('Options').patch({ visible: false }) 
    this._setState('MenuListFocus'); 
  }

  static get width() {
    return 135
  }
 
  static get height() {
    return 120
  }
}

