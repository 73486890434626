import { Lightning, Log } from '@lightningjs/sdk'
import { Registry } from '@lightningjs/sdk'
export default class TimeGrid1 extends Lightning.Component {
    // ------------
 
  static _template() {
    if(localStorage.getItem('language')=="en"){
      return {
        Wrapper: {
          w: (w) => w,
          h: 60,
          rect: true,
          shader: {type:  Lightning.shaders.RoundedRectangle, radius: [0, 0, 10,10], stroke: 2 ,strokeColor: 0x00d1d3d4,},
          // color:0xff0D2B3D,// color: 0xff0D2B3D, // Dark teal blue color,0xffff2323,
          color: 0x00ffffff , //0xaa035767,// 0x77777777,//
          Boxid: this.bindProp('boxid'),
           Text: {
            mountY: 0.5,
            y:(h)=>h/2+1,
            x:20,
            color: 0xffffffff,
            alpha:0,
            text: {
              text: this.bindProp('title'),
              fontFace: 'Regular',
              fontSize: 24,
              textAlign: 'left',
            }, 
            shader: {
              type:  Lightning.shaders.RoundedRectangle, radius: [0, 0,0,0], stroke: 0,strokeColor:  0x88ffffff,
            },   
      
          },
         
          LiveLine:{  
            y:-10,
            zIndex:10,  
            rect:true,
            x:this.bindProp('distances'),    
            // x:50,
            // x:$distance,
            h:576,
            w:3,
            color: 0xffA00000,  
            collision:true,
            visible:this.bindProp('status'),  
            // visible:false,  
            Box:{
              y:0, 
              x:1.5, 
              mountX:0.5, 
              rotation:0.785,
              rect:true,
              h:20, 
              w:20,
              color: 0xffA00000,  
              collision:true,  
              zIndex:10,    
              shader: {
                type:  Lightning.shaders.RoundedRectangle, radius: [0, 0,0,0], stroke: 0,strokeColor:  0x88ffffff,
              }
            },   
          },
        },
        // HighLight: {
        //   y:10,
        //   w: (w) => w + 1,
        //   h: (h) => h + 1,
        //   alpha: 1,
        //   rect: true,
        //   shader: {
        //     type: Lightning.shaders.RoundedRectangle,
        //     radius: 5.4,
        //     stroke: 1,
        //     strokeColor: 0xffd1d3d4,
        //     fillColor: 0x00000000,
        //   },
        //   visible: false,
        // },
      }
    }
    else{
    return {
      Wrapper: {
        w: (w) => w,
        h: 60,
        rect: true,
        shader: {type:  Lightning.shaders.RoundedRectangle, radius: [0, 0, 10,10], stroke: 2 ,strokeColor: 0x00d1d3d4,},
        // color:0xff0D2B3D,// color: 0xff0D2B3D, // Dark teal blue color,0xffff2323,
        color: 0x00ffffff , //0xaa035767,// 0x77777777,//
        Boxid: this.bindProp('boxid'),
         Text: {
          mountY: 0.5,
          y:(h)=>h/2+1,
          x:20,
          color: 0xffffffff,
          alpha:0,
          text: {
            text: this.bindProp('title'),
            fontFace: 'Regular',
            fontSize: 24,
            textAlign: 'left',
          }, 
          shader: {
            type:  Lightning.shaders.RoundedRectangle, radius: [0, 0,0,0], stroke: 0,strokeColor:  0x88ffffff,
          },   
             shader: {type: Lightning.shaders.Perspective, rx: 3.14}, 
        },
       
        LiveLine:{  
          y:-10,
          zIndex:10,  
          rect:true,
          x:this.bindProp('distances'),    
          // x:50,
          // x:$distance,
          h:576,
          w:3,
          color: 0xffA00000,  
          collision:true,
          visible:this.bindProp('status'),  
          // visible:false,  
          Box:{
            y:0, 
            x:1.5, 
            mountX:0.5, 
            rotation:0.785,
            rect:true,
            h:20, 
            w:20,
            color: 0xffA00000,  
            collision:true,  
            zIndex:10,    
            shader: {
              type:  Lightning.shaders.RoundedRectangle, radius: [0, 0,0,0], stroke: 0,strokeColor:  0x88ffffff,
            }
          },   
        },
      },
      // HighLight: {
      //   y:10,
      //   w: (w) => w + 1,
      //   h: (h) => h + 1,
      //   alpha: 1,
      //   rect: true,
      //   shader: {
      //     type: Lightning.shaders.RoundedRectangle,
      //     radius: 5.4,
      //     stroke: 1,
      //     strokeColor: 0xffd1d3d4,
      //     fillColor: 0x00000000,
      //   },
      //   visible: false,
      // },
    }
  }
  }
 
  // _init(){
  //   this.distances=0;
  //   this.timenow=0;
  // }
 
  async _setup() {
    let currentTime =0;
   
    let timenoww=printTime1();
    // currentTime=50;
 
    function printTime() {
    const now = new Date();
    let minutes = now.getMinutes().toString().padStart(2, '0');
 
     const timeString = `${minutes}`;  
 
    return timeString;
    }
 
    function printTime1() {
      const now = new Date();
      let hours = now.getHours().toString().padStart(2, '0');
      let minutes = now.getMinutes().toString().padStart(2, '0');
   
      const timeString1 = `${hours}:${minutes}`;
   
      return timeString1;
    }
   
   
    setInterval(printTime1, 60000);
 
 
    setInterval(printTime, 1000);
 
    let distance=0;
 
    function calcDistance(){
    currentTime= printTime();
    distance=628/60;
    distance=distance*currentTime;
     return distance;
    }
 
    setInterval(calcDistance, 10000);  
    // setInterval(  this.fireAncestors('$liveupto', distance), 5000); 
   
    this.distances=calcDistance();
    this.timenow=timenoww;
  

    Registry.setInterval(() => {
      
      // this.tag('Grids').reload(timeItems1)  
      // this.tag('TimeWrapper').reload(TimeItems)
    
    
    let currentTime =0;
   
    let timenoww=printTime1();
    // currentTime=50;
 
    function printTime() {
    const now = new Date();
    let minutes = now.getMinutes().toString().padStart(2, '0');
 
     const timeString = `${minutes}`;  
 
    return timeString;
    }
 
    function printTime1() {
      const now = new Date();
      let hours = now.getHours().toString().padStart(2, '0');
      let minutes = now.getMinutes().toString().padStart(2, '0');
   
      const timeString1 = `${hours}:${minutes}`;
   
      return timeString1;
    }
   
   
    setInterval(printTime1, 60000);
 
 
    setInterval(printTime, 1000);
 
    let distance=0;
 
    function calcDistance(){
    currentTime= printTime();
    distance=628/60;
    distance=distance*currentTime;
     return distance;
    }
 
    setInterval(calcDistance, 10000);  
    // setInterval(  this.fireAncestors('$liveupto', distance), 5000); 
   
    this.distances=calcDistance();
    this.timenow=timenoww;
  
   }, 10000)
  }
 
 
 
 
 
 
  _focus() {
    this.tag('HighLight').patch({ visible: true })
   
  }
 
  _unfocus() {
    this.tag('HighLight').patch({ visible: false })
  }
 
  static get width() {
    return 628
  }
   
  static get height() {
    return 60
  }
}  
 
 